import React, { useState,useEffect, useRef } from 'react';
import { filter, result } from 'lodash';
import BarChart from './BarChart';
import Iconify from 'src/components/Iconify';
import {
  InputAdornment,
  Grid,
  Box,
  Autocomplete,
  Button,
  TextField,
  Typography,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableContainer,TablePagination,Toolbar,OutlinedInput
} from '@mui/material';
import PropTypes from 'prop-types';
import Scrollbar from 'src/components/Scrollbar';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { YearCalendar } from '@mui/x-date-pickers/YearCalendar';
import { Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Page, Text, View, Document, StyleSheet, Image,pdf,PDFDownloadLink,PDFViewer,Font } from '@react-pdf/renderer';
// import logoImage from '../../assets/logo.svg'
import { ViewAgenda } from '@mui/icons-material';
import { Bar } from 'react-chartjs-2';
import { createRoot } from 'react-dom/client'; 
// import {ChartJsImage} from 'chartjs-to-image';
import Chart from 'chart.js/auto';
import { monthlyClientDashboard,getClientGroups } from 'src/api/reports';
// import { generateBarChart  } from './chartUtils';
import McdbReport from './McdbReport';
import BarGraph from './BarGraph';
import html2canvas from 'html2canvas';


const PDFData = ({ data, ServicePeriod ,McdbReportImage}) => {
  console.log("data before adding chart",McdbReportImage);
  const [barChart, setBarChart] = useState(null);
  const [pieChart, setPieChart] = useState(null);
  
  useEffect(() => {
    const fetchCharts = async () => {
      // const barChartData = Details.map(item => ({ label: item.devicename, value: item.EventNo })); // Adjust data as needed
      const barChartData = data[0]?.camera_wise_counts || [];

      console.log("barChartData barChartURL....",barChartData)

      const pieChartData = [
        { label: 'True Events', value: true_event_count },
        { label: 'Escalations', value: escalated_event_count },
        // Add other categories as necessary
      ];
      // if (barChartData.length > 0) {
      //   debugger
      //   const barChartURL = await generateBarChart(barChartData);
      //   console.log("barChartURL....",barChartURL)
      //   debugger
      //   setBarChart(barChartURL);
      // }
      // const barChartURL = await generateBarChart(barChartData);BarGraph
      // const pieChartURL = await generatePieChart(pieChartData);

      // setBarChart(barChartURL);
      // setPieChart(pieChartURL);
    };

    fetchCharts();
  }, [data]);

  let month = '', year = 0;
  if (ServicePeriod.length > 0) {
    month = ServicePeriod[0].name;
    year = ServicePeriod[1].name;
  }
  if (data.length === 0) {
    return null;
  }

  const headers = ["Sno", "Event Number", "Camera Name", "Escalation Url"];
  let { cameras: camera = 0, total_event_count = 0, escalated_event_count = 0, true_event_count = 0, Details = [] ,camera_wise_counts = []} = (Object.keys(data).length > 0) ? data[0] : {};

  Details = Details.map((data, index) => ({
    ...data,
    sno: index + 1,
  }));

  camera_wise_counts = camera_wise_counts.map((data, index) => ({
    ...data,
    sno: index + 1,
  }));

  return (
    <Document>
      <Page size="A4">
        <View style={{ width: 100, marginTop: 20, height: 40 }}>
          {/* <Image src='static/preview.jpg' /> */}
        </View>

        <View style={{ marginTop: 20 }}>
          <Text style={{ fontSize: 12, fontWeight: 'bold', marginLeft: 10 }}>VDOintel Service Overview Dashboard</Text>
        </View>

        <View style={{ marginTop: 20, backgroundColor: 'black', color: 'white', textAlign: 'center', padding: 10, marginLeft: 10, marginRight: 10 }}>
          <Text style={{ fontSize: 12 }}>SERVICE HIGHLIGHTS</Text>
        </View>

        <View style={{ display: 'flex', flexDirection: 'row', marginTop: 2, marginLeft: 10, marginRight: 10 }}>
          <View style={{ flex: 3 }}>
            <Text style={{ fontSize: 12, fontWeight: 'bold', backgroundColor: 'black', color: 'white', textAlign: 'center', padding: 10 }}>SERVICE PERIOD</Text>
            <Text style={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center', marginTop: 5 }}>{month}-{year}</Text>
          </View>
          <View style={{ flex: 5 }} />
          <View style={{ flex: 4 }}>
            <Text style={{ fontSize: 12, fontWeight: 'bold', backgroundColor: 'black', color: 'white', textAlign: 'center', padding: 10 }}>NUMBER OF CAMERAS</Text>
            <Text style={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center', marginTop: 5 }}>{camera}</Text>
          </View>
        </View>

        <View style={{ display: 'flex', flexDirection: 'row', marginTop: 5, marginLeft: 10, marginRight: 10 }}>
          <View style={{ flex: 3 }}>
            <Text style={{ fontSize: 12, fontWeight: 'bold', backgroundColor: 'black', color: 'white', textAlign: 'center', padding: 3.5 }}>TOTAL EVENTS{'\n'}TRIGGERED</Text>
            <Text style={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center', marginTop: 5 }}>{total_event_count}</Text>
          </View>
          <View style={{ flex: 5, marginLeft: 1, marginRight: 1 }}>
            <Text style={{ fontSize: 12, fontWeight: 'bold', backgroundColor: 'black', color: 'white', textAlign: 'center', padding: 10 }}>TRUE EVENTS</Text>
            <Text style={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center', marginTop: 5 }}>{true_event_count}</Text>
          </View>
          <View style={{ flex: 4 }}>
            <Text style={{ fontSize: 12, fontWeight: 'bold', backgroundColor: 'black', color: 'white', textAlign: 'center', padding: 10 }}>TOTAL ESCALATIONS</Text>
            <Text style={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center', marginTop: 5 }}>{escalated_event_count}</Text>
          </View>
        </View>

        <View style={{ marginTop: 20, backgroundColor: 'black', color: 'white', textAlign: 'center', padding: 10, marginLeft: 10, marginRight: 10 }}>
          <Text style={{ fontSize: 12, fontWeight: 'bold' }}>TREND OF AUDITS BY CAMERA</Text>
        </View>
{/* 
        <View style={styles.chartContainer}>
          {/* {barChart ? <Image src={barChart} /> : <Text>Loading chart...</Text>} */}

           {/* <McdbReport></McdbReport> */}
          {/* <View style={styles.chartContainer}>
  <Image src='/home/pst/Desktop/istockphoto-877369086-612x612.jpg' />
</View> */}

        {/* </View> */} 

        <View style={styles.chartContainer}>
          {McdbReportImage && <Image src={McdbReportImage} />}
        </View>

        <View style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
          <View style={{ flex: 4 }}>
            {/* {pieChart && <Image src={pieChart} />} */}
          </View>
          <View style={{ flex: 6, marginLeft: 10, marginRight: 10, border: '3px solid black', padding: 10 }}>
            <View>
              <Text style={{ fontSize: 12, fontWeight: 'bold' }}>Definitions:</Text>

              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.bullet}>{'\u2022'}</Text>
                <Text style={styles.definition}>Events triggered - Video feeds are transmitted in real time whenever there is motion detected in the camera view</Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.bullet}>{'\u2022'}</Text>
                <Text style={styles.definition}>True Event - Video feed that has any kind of person or vehicle movement is categorized as a true event.</Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.bullet}>{'\u2022'}</Text>
                <Text style={styles.definition}>False Event - Events triggered by motion of Animals/Insects/Rain/Snow/Plant movement due to air are categorized under false event.</Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.bullet}>{'\u2022'}</Text>
                <Text style={styles.definition}>Escalation - Command center agent filters the event into false alarms/unusual activity. When unusual activity is identified, necessary action is taken by command center as per protocol and the evidence is escalated to the client as evidence.</Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.bullet}>{'\u2022'}</Text>
                <Text style={{ fontSize: 10, padding: 4, lineHeight: 1.5, color: 'red' }}>Note - All the appended URLs are only valid up to 180 days from the date of escalation.</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{ marginTop: 20, backgroundColor: 'lightblue', color: 'black', textAlign: 'center', padding: 10, marginLeft: 10, marginRight: 10 }}>
          <Text style={{ fontSize: 12, fontWeight: 'bold' }}>URL's OF ESCALATED EVENTS</Text>
        </View>

        <View style={{ marginLeft: 10, marginRight: 10 }}>
          <View style={styles.tableContainer}>
            <Text style={styles.Sno}>Sno</Text>
            <Text style={styles.EventNo}>Event No</Text>
            <Text style={styles.CameraName}>Camera Name</Text>
            <Text style={styles.url}>Escalated Url</Text>
          </View>
          {Details.map(item =>
            (<View style={styles.row} key={item.sno.toString()}>
              <Text style={styles.SnoCol}>{item.sno}</Text>
              <Text style={styles.EventNoCol}>{item.EventNo}</Text>
              <Text style={styles.CameraNameCol}>{item.devicename}</Text>
              <Text style={styles.urlCol}>{item.VideoUrl}</Text>
            </View>))}
        </View>

      </Page>
    </Document>
  );
};

const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    // justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
  }));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    marginLeft: 15,
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`,
    },
  }));


  const months = [
    { name: 'January', value: '1' },
    { name: 'February', value: '2' },
    { name: 'March', value: '3' },
    { name: 'April', value: '4' },
    { name: 'May', value: '5' },
    { name: 'June', value: '6' },
    { name: 'July', value: '7' },
    { name: 'August', value: '8' },
    { name: 'September', value: '9' },
    { name: 'October', value: '10' },
    { name: 'November', value: '11' },
    { name: 'December', value: '12' }
  ];

  const currentYear = new Date().getFullYear();
  
  const years = //[currentYear - 1,currentYear,currentYear + 1];
  [
    { name:(currentYear - 1).toString(),value:currentYear - 1 },
    { name:(currentYear).toString(),value:currentYear },
    { name:(currentYear + 1).toString(),value:currentYear + 1 }
  ]


const styles = StyleSheet.create({
    title: { 
      fontFamily: 'Open Sans', 
      fontSize: 20, 
      fontWeight: 600 },
    page: {
      flexDirection: 'row',
      backgroundColor: 'white'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    image: {
      width: 200,
      height: 100
    },
    chartContainer: {
        width: '100%',
        height: 200,
        marginTop: 20,
      },
      header: {
        fontSize: 12,
        fontWeight: 'bold',
      },
      definition: {
        fontSize: 10,
        padding:4,
        lineHeight: 1.5
      },

      tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderWidth: 1,
        borderColor: 'black',
        lineHeight:2,
        alignItems:'center',
        textAlign: 'center',
        fontStyle: 'bold',
    },
    url: {
      width: '60%',
      fontSize:10
    },
    Sno: {
        width: '5%',
        borderRightWidth: 1,
        fontSize:10
    },
    EventNo: {
        width: '20%',
        borderRightWidth: 1,
        fontSize:10
    },
    CameraName: {
        width: '15%',
        fontSize:10,
        borderRightWidth: 1,
        
    },
    row: {
      flexDirection: 'row',
      borderBottomColor: 'black',
      borderBottomWidth: 1,
      alignItems: 'center',
      lineHeight:2
    },
    urlCol: {
      width: '60%',
      textAlign: 'left',
      borderRightWidth: 1,
      paddingLeft: 8,
      fontSize:9,
      overflow: 'hidden'
    },
    SnoCol: {
        width: '5%',
        textAlign: 'right',
        paddingRight: 8,
        borderRightWidth:1,
        borderLeftWidth:1,
        fontSize:8,
        lineHeight:4,
    },
    EventNoCol: {
        width: '20%',
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
        fontSize:9,
        lineHeight:4
    },
    CameraNameCol: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
        borderRightWidth: 1,
        fontSize:9,
        lineHeight:4
    },
     table: { 
      display: "table", 
      width: "auto", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderBottomWidth: 0 
  }
  });

const MonthlyClientDahBoard = ({clientList}) => {
  const mcdbReportRef = useRef(null);
  const [mcdbReportImage, setMcdbReportImage] = useState(null);
  //console.log(clientList)
//view images // using URL (local/serve  need to givr yhr )
  const options3 = clientList

  const [filterName, setFilterName] = useState('');  

  const [value1, setValue1] = useState(null);
  const [value2, setValue2] = useState(null);
  const [value3, setValue3] = useState(null);
  const [value4, setValue4] = useState(null);
      
      

  const [loadDocument,setLoadDocument]=useState(false)

  const [showChart,setShowChart]=useState(false)


  const handleButtonClick = () => {
    
    if(!value3){
      setError(true)
      setIsClientSelected(true)
    }

    if(!value1){
      setError(true)
      setIsMonthSelected(true)
    }

    if(!value2){
      setError(true)
      setIsYearSelected(true)
    }



    if(value1 && value2 && value3){
      let body={month:value1.value,year:value2.value,client_id:value3.ID}
      monthlyClientDashboard(body,(response)=>{
        
         if(response.status===200){
            if(response.data.length>0){
              console.log("response")
              setPieChartData(response.data)
              setShowChart(true)
              setLoadDocument(true)
              //set load document for pdf viewer
              //PDFData(pieChartData);
            }
           else{
              setShowChart(false)
           }
         }
      })



    if (mcdbReportRef.current) {
      html2canvas(mcdbReportRef.current).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        console.log("image view ",imgData);
        setMcdbReportImage(imgData);
      });
    }
      
    }   
  };

const [data, setData] = useState([])
const [row,rowChange] = useState([])
const [page,pageChange]=useState(0)
const [rowPerPage,rowPerPageChange]=useState(5)

// useEffect(() => {
//     setData(values)
// }, [])

const handleChangePage = (event,newpage)=>{
    if(newpage===0){
        pageChange(0);
    }
    else{
        pageChange(newpage)
    }
}

const handleRowsPerPage = (e)=>{
    rowPerPageChange(e.target.value)
    pageChange(0)
}

const handleFilterByName = (event) => {
    const pattern=event.target.value.trim()
    setFilterName(pattern);
  };

  useEffect(()=>{
    // console.log(filterName)
  },[filterName])

  const convertImageToBase64 = (file, callback) => {
    debugger
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  };


 const [pieChartData,setPieChartData]=useState([])

//  useEffect(()=>{
//   debugger;
//   console.log(pieChartData)
//   if(pieChartData.length>0){
//     PDFData(pieChartData)
//   }
//  },[pieChartData])


  const [error,setError]=useState(false)
  const [isMonthSelected,setIsMonthSelected]=useState(false)
  const [isYearSelected,setIsYearSelected]=useState(false)
  const [isClientSelected,setIsClientSelected]=useState(false)
  const [groups,setGroups]=useState([])
  const [deviceStats,setDeviceStats] = useState([]);

  const loadClientGroups = (newValue)=>{
    setIsClientSelected(false)
    setValue3(newValue)
    //console.log(newValue)
    if(newValue){
      let body={client_id:newValue.ID}
      getClientGroups(body,(response)=>{
        if(response.status){
          setGroups(response.data)
        }
      })
    }
  }


  return (
    <Grid sx={{ marginLeft: '1rem' }}>
        <Grid container spacing={2} alignItems="center" sx={{marginTop:'0.2rem'}}>
        <div ref={mcdbReportRef}>
        <McdbReport ></McdbReport>
        {/* <BarGraph data={pieChartData}></BarGraph> */}
        </div>            
        
        <Grid item xs={3}>
                <Typography variant="subtitle2" component="div">
                Client
                </Typography>
                <Autocomplete
                value={value3}
                onChange={(event, newValue) => {
                    if(newValue!==null){
                      loadClientGroups(newValue)
                    }
                }}
                options={clientList}
                getOptionLabel={(option)=>option.displayname}
                isOptionEqualToValue={(option, value) => option.ID === value.ID} 
                renderOption={(props, option) => (
                  <li {...props} key={option.ID}>  
                    {option.displayname} 
                  </li>
                )}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    placeholder="Select Client"
                    variant="outlined"
                    error={error && isClientSelected}
                    />
                )}
                />
                 {error && isClientSelected && <Typography variant="body2" color="error">Select Client</Typography>}
            </Grid>

            <Grid item xs={3}>
                <Typography variant='subtitle2' component="div">
                Month
                </Typography>

                <Autocomplete
                value={value1}
                onChange={(event, newValue) => {
                   if(newValue!==null){
                    setIsMonthSelected(false)
                    setLoadDocument(false)
                    setValue1(newValue);
                   }
                }}
                options={months}
                getOptionLabel={(option)=>option.name}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    placeholder="Select Month"
                    variant="outlined"
                    error={error && isMonthSelected}
                    />
                )}
                />
                 {error && isMonthSelected && <Typography variant="body2" color="error">Select Month</Typography>}
            </Grid>
            
            <Grid item xs={3}>
                <Typography variant="subtitle2" component="div">
                Year
                </Typography>
                <Autocomplete
                value={value2}
                onChange={(event, newValue) => {
                   if(newValue!==null){
                    setLoadDocument(false)
                    setIsYearSelected(false)
                    setValue2(newValue);
                   }
                }}
                options={years}
                getOptionLabel={(option)=>option.name}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    placeholder="Select Year"
                    variant="outlined"
                   error={error && isYearSelected}
                    />
                )}
                />
                {error && isYearSelected && <Typography variant="body2" color="error">Select Year</Typography>}
       
            </Grid>

            <Grid item xs={1} sx={{marginTop:2}}>
                    <Button variant="contained" onClick={handleButtonClick}>
                      Generate
                    </Button>           
            </Grid>

            <Grid item xs={3} sx={{marginBottom:2}}>
                <Typography variant="subtitle2" component="div">
                Group
                </Typography>
                <Autocomplete
                value={value4}
                onChange={(event, newValue) => {
                    if(newValue!==null){
                      setValue4(newValue);
                    }
                }}
                options={groups}
                getOptionLabel={(option)=>option.GroupName}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    placeholder="Select Group"
                    variant="outlined"
                    />
                )}
                />
               </Grid>

        
        </Grid>

      
        {showChart && loadDocument && <Grid container spacing={2} alignItems="center" sx={{ marginTop: '2rem' }}>           
              <PDFDownloadLink key={2024} document={<PDFData data={pieChartData} ServicePeriod={[value1,value2]}/>}  McdbReportImage={mcdbReportImage} fileName="MonthlyClinetDashBoard.pdf">
                      {({ blob, url, loading, error }) => (
                      <Button variant="contained" disabled={loading} onClick={!loading ? null : () => {}}>
                          {loading ? 'Loading document...' : 'Download File'}
                      </Button>
                      )}
              </PDFDownloadLink>
              <PDFViewer style={{ width: '100%', height: '900px' }} showToolbar={false}   McdbReportImage={mcdbReportImage}>
                  <PDFData data={pieChartData} ServicePeriod={[value1,value2]}  McdbReportImage={mcdbReportImage} />
              </PDFViewer>
        </Grid>}
        
        

        
    </Grid>
  );
};

export default MonthlyClientDahBoard;