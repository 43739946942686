import React from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const BarGraph = ({ data }) => {

  const cameras = data.map(item => item.devicename);

  // Extracting TrueEvents, FalseEvents, and EscalatedEvents data
  const trueEvents = data.map(item => item.true_event_count);
  const falseEvents = data.map(item => item.false_event_count);
  const escalatedEvents = data.map(item => item.escalated_count);

  // Find the maximum value among all event counts
  const maxCount = Math.max(
    ...trueEvents,
    ...falseEvents,
    ...escalatedEvents
  );
  const nearestMultipleOf50 = Math.ceil(maxCount / 50) * 50;

  const chartData = {
    labels: cameras,
    datasets: [
      {
        label: 'True Events',
        backgroundColor: 'rgba(79, 169, 44 , 1)',
        data: trueEvents
      },
      {
        label: 'False Events',
        backgroundColor:  'rgba(255, 181, 7 , 1)',
        data: falseEvents
      },
      {
        label: 'Escalated Events',
        backgroundColor: 'rgba(220, 61, 61 , 1)',
        data: escalatedEvents
      }
    ]
  };

  const options = {
    indexAxis: 'y',
    scales: {
      x: {
        beginAtZero: true,
        max: nearestMultipleOf50 // Add a buffer for better visualization(maxCount / 50) * 50
      },
      y: {
        beginAtZero: true
      }
    }
    // ,
    // plugins: {
    //   datalabels: {
    //     anchor: 'end',
    //     align: 'end',
    //     formatter: (value, context) => {
    //       return value; // Display the value of the bar
    //     }
    //   }
    // }
  };

  return (
    <div>
      {/* <Bar data={chartData} options={options} plugins={[ChartDataLabels]} /> */}
      <Bar data={chartData} options={options} />

    </div>
  );
};

export default BarGraph;

