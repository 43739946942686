import { filter } from 'lodash';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Link,
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import {
    CameraMoreMenu,
    CameraForm,
    CameraAlert
} from '../sections/cameras';

import { ListHead, ListToolbar } from '../components/table'
// mock
import useCameras from '../api/useCameras'


// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'deviceid', label: 'Camera ID', alignRight: false },
    { id: 'devicename', label: 'Camera Name', alignRight: false },
    { id: 'substream', label: 'Stream URL', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false }    
  ];

if (localStorage.getItem('ID') === '2')
    TABLE_HEAD[4] = { id: '', label: 'Action', alignRight: false }

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => `${_user.devicename} ${_user.deviceid} ${_user.substream} ${_user.status === 1 ? 'active': 'inactive'}`.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function Cameras() {
    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [formDialog, setFromDialog] = useState({
        open: false,
        id: null,
        devicename: '',
        substream: ''
    });

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = alldata.data.map((n) => n.devicename);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const {
        alldata,
        addCamera,
        isLoadForm,
        isReloadData,
        updateStatus,
        deleteRecord
    } = useCameras()

    // console.log('locations', locations)


    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - alldata.data.length) : 0;

    const filteredCameras = applySortFilter(alldata.data, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredCameras.length === 0;


    function handleDialog() {
        setFromDialog({...formDialog, id: null, open: true})
    }

    const [ confirmalert, setAlert ] = useState({
        title: '',
        content: '',
        open: false,
        id: null,
        status: null
    })

    const handleAlertClose = () => setAlert({...confirmalert, open:false})

    const handleAlertOpen = (id, status) => {
        
        let title = status === 0 ? 'InActive Alert' : 'Delete Alert'
        let content = status === 0 ? 'Are you sure want to inactive this record': 'Are you sure want to delete this record'
        setAlert({
            title: title,
            content: content,
            open: status !== 1,
            id: id,
            status: status
        })
        if (status === 1)
            handleStatus(id, status)
        
    }

    const handleStatus = (id, status) => {
        let body = { uid: id }
        if (status !== 2) {
            body.Status = status            
            updateStatus(body)
        } else 
            deleteRecord(body)

        handleAlertClose()
        isReloadData(true)        
    }

    function copyTextToClipboard(text) {
        try {
            if(navigator.clipboard) {
                navigator.clipboard.writeText(text).then(() => {
                    console.log('Text has been copied to the clipboard');
                })
            }          
        } catch (err) {
            console.error('Unable to copy text to clipboard');
        }      
    }

    const handlePlay = (stream) => {
        try {
            window.JSBridge.showMessageInNative(stream)
        } catch (e) {
            copyTextToClipboard(stream);
        }
        
            
        // callHandler("callNativeMethod", stream, function (resp) {
        //     console.log("from js to native " + resp);
        //   })
    }

    return (
        <Page title="Locations">
            <Container>
                <CameraAlert
                alert={confirmalert.open}
                handleAlertClose={handleAlertClose}
                title={confirmalert.title}
                content={confirmalert.content}
                locationId={confirmalert.id}
                status={confirmalert.status}
                handleStatus={handleStatus}
                />
                <CameraForm
                    formDialog={formDialog}
                    setFromDialog={setFromDialog}
                    alldata={alldata}
                    addCamera={addCamera}
                    isLoadForm={isLoadForm}
                    isReloadData={isReloadData} />
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Cameras
                    </Typography>
                    
                    {localStorage.getItem('ID') === '2' && (
                        <Button variant="contained" onClick={handleDialog} component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
                        New Camera
                         </Button>
                    )}
                    
                </Stack>

                <Card>
                    <ListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <ListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={alldata.data.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredCameras.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const { uid, devicename, deviceid, substream, Status } = row;                                        
                                        return (
                                            <TableRow
                                                hover
                                                key={uid}
                                                tabIndex={-1}
                                            >

                                                <TableCell align="left">{deviceid}</TableCell>
                                                <TableCell align="left">{devicename}</TableCell>
                                                <TableCell align="left">{substream}</TableCell>
                                                {/* <TableCell align="left"><Link component="button"
                                                    variant="body2"
                                                    onClick={() => handlePlay(substream)}
                                                    >{substream}</Link></TableCell> */}
                                                <TableCell align="left">
                                                    <Label variant="ghost" color={Number(Status) === 1 ? 'success' : 'error'}>
                                                        {Number(Status) === 1 ? 'Active' : 'InActive'}
                                                    </Label>
                                                </TableCell>
                                                {(localStorage.getItem('ID') === '2') && (
                                                    <TableCell align="center">
                                                        <CameraMoreMenu
                                                        handleAlertOpen={handleAlertOpen}
                                                        uid={uid}
                                                        devicename={devicename}
                                                        substream={substream}
                                                        Status={Status}
                                                        setFromDialog={setFromDialog}
                                                        />
                                                    </TableCell>
                                                )}                                                
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={alldata.data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    );
}
