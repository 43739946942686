import PropTypes from 'prop-types';
// material
import { Grid } from '@mui/material';
import AuditListCard from './AuditListCard';
import { AuditPlayer } from '../@dashboard/audit';
import { useEffect, useState } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";

// ----------------------------------------------------------------------

AuditList.propTypes = {
  audits: PropTypes.array.isRequired
};

export default function AuditList({ clientId,audits, loadMoreAudits, updateToClient, isEscalating, ...other }) {

  const agentType = localStorage.getItem('IsAgentBased');

  const initValues = {
    src: null,
    isOpen: false
  }
  const [player, setPlayer] = useState(initValues)
  function handleDialog (audit) {
    const isOpen = true
    setPlayer ((plyr) => { return {...AuditPlayer, isOpen, ...audit }})
    // console.log('Init', player)
  }

  function handleDialogUS (audit) {
    const isOpen = true
    setPlayer ((plyr) => { return {...AuditPlayer, isOpen, ...audit }})
    // console.log('Init', player)
  }

  return (
    <InfiniteScroll
          dataLength={audits.length}
          next={loadMoreAudits}
          hasMore={true}
          // loader={<h4>Loading...</h4>}
          sx={{width: '100%'}}
        >       
    <Grid container spacing={1} {...other}>
      <AuditPlayer player={player} setPlayer={setPlayer} updateToClient={updateToClient} isEscalating={isEscalating} clientId={clientId}/>
      
        {audits.map((audit, idx) => (
          <Grid key={idx} item xs={12} sm={6} md={4} lg={4}>
        <AuditListCard audit={audit} handleDialog={handleDialog} />



          </Grid>
        ))}
      
    </Grid>
    </InfiniteScroll>
  );
}
