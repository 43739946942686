import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  Card,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  Grid,
  CardContent,Stack,DialogActions,
  Dialog,
  IconButton,
  InputAdornment,
  DialogContent,
  Box,
} from "@mui/material";
import * as Yup from "yup";
import Scrollbar from "src/components/Scrollbar";
import { postNewUserData ,uploadImagedData ,getUserDetails,updateUserData,disableUserData} from "src/api/addAgent";
import axios from "src/axios";
import { countBy } from "lodash";
import { UpdateDisabled } from "@mui/icons-material";
import Iconify from "src/components/Iconify";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
const AddAgent = () => {
 
  const [userType, setUserType] = useState('');
  const [agentName, setAgentName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [userNewName, setNewUsername] = useState('');
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [image, setImage] = useState(null);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [imageConvertedData,setImageData] = useState('');
  const [finalImageURL,setFinalImage] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [visiblePasswordIndex, setVisiblePasswordIndex] = useState(null);

  //Error Messages 
  const [userTypeError,setuserTypeError] = useState('');
  const [agentNameError,setagentNameError] = useState('');
  const [mobileNumberError,setmobileNumberError] = useState('');
  const [userNewNameError,setuserNewNameError] = useState('');
  const [passwordError,setpasswordTypeError] = useState('');
  const [confirmPasswordError,setconfirmPasswordError] = useState('');
  const [imageError,setImageError]=useState('')
  const [userDetails,setUserDetails] = useState([]);

  //Edit Dailog box
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [selectedRowdata,setSelectedRowData] = useState([]);

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');

  const [isEdited,setIsEdited]=useState(false)
  const [prvValue,setPrvValue]=useState('')

  const [rowData,setId]=useState([])


  const [openMessage,setOpenMessage]=useState(false)

  const [dialogData, setDialogData] = useState({
    userType: '',
    agentName: '',
    mobileNumber: '',
    userNewName: '',
    password: '',
    confirmPassword: '',
    image: null,
  });

  const [editedData, seteditedData] = useState({
    userType: '',
    agentName: '',
    mobileNumber: '',
    userNewName: '',
    password: '',
    confirmPassword: '',
    image: null,
    imageurl:'',
    UserID:''
  });




  useEffect(()=>{
    getUserDetails((response)=>{
      if(response.status === 200){
        // console.log("userData..............", response.data);
        setUserDetails(response.data)

      }
    })
  },[])

  // useEffect(() => {
  //   //debugger
  //   setImageData('')
  //   setImageError('')
  //   if(image != null){
  //     // console.log("Iamge... uploaded",image)
  //     const fileName = image.name;
  //     const image_file = {
  //       Imagepath : `Agent/${fileName}`,
  //       Imagefile : image
  //     }
  //     // console.log(image_file)
  //     uploadImagedData(image_file,(response)=>{
  //       if(response.status === 200){
  //         console.log("image Data",response.data);

  //           setImageData(response.data)
  //       }
      
  //     }) 
  //   }
  //   else if(editedData.image){
  //     const fileName = editedData.image.name;
  //     const image_file = {
  //       Imagepath : `Agent/${fileName}`,
  //       Imagefile : editedData.image
  //     }
  //     uploadImagedData(image_file,(response)=>{
  //       if(response.status === 200){
  //         // console.log("image Data",response.data);
  //           setImageData(response.data)
  //       }     
  //     }) 
  //   }



  // },[image,editedData.image])

  useEffect(() => {
    setImageData('');
    setImageError('');

    const uploadImage = (imageFile) => {
        const fileName = imageFile.name;
        const image_file = {
            Imagepath: `Agent/${fileName}`,
            Imagefile: imageFile
        };
        uploadImagedData(image_file, (response) => {
            if (response.status === 200) {
                console.log("image Data", response.data);
                setImageData(response.data);
            }
        });
    };

    if (image != null) {
        console.log("Image uploaded", image);
        uploadImage(image);
    } else if (editedData.image) {
        console.log("Edited image uploaded", editedData.image);
        uploadImage(editedData.image);
    }
}, [image, editedData.image]);


  useEffect(() => {
    //debugger
    if(imageConvertedData.length){
      const instance = process.env.REACT_APP_DEV_IMAGE_API;
      // console.log("instance",instance)
      const baseUrl = "http://0.0.0.0:8070";
      imageConvertedData[0].Imagepath = imageConvertedData[0].Imagepath.replace(baseUrl, "");
    
      const newImagePath = `${instance}${imageConvertedData[0].Imagepath}`;
      setFinalImage(newImagePath)
      // console.log("image path", newImagePath); 
    }
  },[imageConvertedData])

  const handleUserTypeChange = (event,val) => {
    console.log("handel user change ",event.target.value);
    //debugger
    if(val==='add'){
      setDialogData({ ...dialogData, userType: event.target.value });
      setUserType(event.target.value)
    }
    else{
      seteditedData({...editedData,userType:event.target.value})
    }
   
  };

  const handleAgentNameChange = (event,val) => {
    if(val==="add"){
      setDialogData({ ...dialogData, agentName: event.target.value });
      setAgentName(event.target.value)
    }
    else{
      seteditedData({...editedData,agentName:event.target.value})
    }
   
  };

  const handleMobileNumberChange = (event,val) => {
        
        let value=event.target.value;
        value=value.replace(/\D/g, '')
        value=value.slice(0,10)

       // console.log(value)
        if(val==="add"){
          setDialogData({ ...dialogData, mobileNumber:value });
          setMobileNumber(value)
        }
        else{
          seteditedData({...editedData,mobileNumber:value})
        }
       
  };
 

  const handleUsername = (event,val) => {
    if(val==="add"){
      setDialogData({ ...dialogData, userNewName:event.target.value });
      setNewUsername(event.target.value)
    }
    else{
      setIsEdited(true)
      setPrvValue(editedData.userNewName)
      seteditedData({...editedData,userNewName:event.target.value})
    }
  };

  const handlePasswordChange = (event,val) => {
    if(val==="add"){
      setDialogData({ ...dialogData,password:event.target.value });
      setPassword(event.target.value)
    }
   else{
    seteditedData({...editedData,password:event.target.value})
   }
  };

  const handleConfirmPasswordChange = (event,val) => {
    if(val==="add"){
      setDialogData({ ...dialogData, confirmPassword: event.target.value });
      setConfirmPassword(event.target.value)
    }
    else{
      seteditedData({...editedData,confirmPassword:event.target.value})
    }
    
  };

  const handleImageChange = (event) => {
    //debugger
    //setDialogData({ ...dialogData, image: event.target.files[0] });
    //setImageError('')
    setImage(event.target.files[0])
  };

  const handleEditedImageChange = (event) => {
    //debugger
    // console.log(event.target.files[0])
    seteditedData({ ...editedData, image: event.target.files[0] });
  };





  const handleSubmit = () => {
    console.log("image",finalImageURL,image);
    let Count = 0;
    setuserTypeError('')
    setagentNameError('')
    setmobileNumberError('')
    setuserNewNameError('')
    setpasswordTypeError('')
    setconfirmPasswordError('')
    setImageError('')
    setFinalImage('')


    if(userType === ''){
      setuserTypeError("Please Select UserType",agentName)
      Count = 1;
     
    }
    if(!agentName){
      setagentNameError("Please Enter the Name")
      Count = 1;
    }
    if(!mobileNumber){
      setmobileNumberError("Please Enter the Mobile Number")
      Count = 1;
    }
    if(mobileNumber.length !== 10){
      setmobileNumberError("Please Enter Valid Mobile Number")
      Count = 1;

    }
    if(!userNewName){
      setuserNewNameError("Please Enter the UserName")
      Count = 1;
    }
    if(!password){
      setpasswordTypeError("Please Enter the Password")
      Count = 1;
    }
    if(!confirmPassword){
      setconfirmPasswordError("Please Enter the ConfirmPassword")
      Count = 1;
    }

    if(password != confirmPassword){
      setconfirmPasswordError("Password and ConfirmPassword not Matching")
      Count = 1;
    }

    // if(!finalImageURL.toString()){
    //   setImageError('Upload Image')
    //   Count = 1;
    // }

    if(image === null){
      setImageError('Upload Image')
      Count = 1;
    }

    const insertData = { 
      UserType: userType,
      displayname: agentName,
      ContactNo:mobileNumber,
      Name: userNewName,
      Password: password,
      Image:finalImageURL.toString()
    }

    // console.log(userDetails)
    // console.log('Inserted Values', insertData)

    const isNameAvail = userDetails.some(item=>item['Name']===insertData.Name)

    if(isNameAvail){
      setuserNewNameError("UserName Already Existed")
      Count=1;
    }

    //console.log(isNameAvail)

 if(Count === 0 ){
    
    postNewUserData(insertData,(response)=>{
      // console.log(response.data)
      setUserType('');
      setAgentName('');
      setMobileNumber('');
      setNewUsername('');
      setPassword('');
      setConfirmPassword('');
      setImage(null);
      getUserDetails((response)=>{
        if(response.status === 200){
          console.log("userData..............", response.data);
          setUserDetails(response.data)

        }
      })
    })
 }


  };

  const handleClear = () => {
    setUserType('');
    setAgentName('');
    setMobileNumber('');
    setNewUsername('');
    setPassword('');
    setConfirmPassword('');
    setImage(null);

    setuserTypeError('')
    setagentNameError('')
    setmobileNumberError('')
    setuserNewNameError('')
    setpasswordTypeError('')
    setconfirmPasswordError('')
    setImageError('')

  }

  const handleEdit = (data) => {
    console.log("data to be edited ",data );
    //debugger //Image
    setShowMessage(false)
    let imageUrl=data.Image?data.Image.substring(data.Image.lastIndexOf('/')+1):'';
    seteditedData({
          userType: data.UserType,
          agentName: data.displayname,
          mobileNumber: data.ContactNo,
          userNewName: data.Name,
          password: data.Password,
          confirmPassword: data.Password,
          image: imageUrl?imageUrl:'',
          imageurl:data.Image,
          UserID:data.ID
    })
    setOpenDialogEdit(true);
  };


  const [editNameError,setEditNameError]=useState('')
  const [mobileError,setMobileError]=useState('')
  const [editedPasswordError,setEditedPasswordError]=useState('')
  const [showMessage,setShowMessage]=useState(false)

  const handleUpdated = () => {
    console.log("editedData ",editedData );
    //debugger
    //setShowMessage(false)
    let Count=0
    const updateData = { 
      ID:editedData.UserID,
      UserType: editedData.userType,
      displayname: editedData.agentName,
      ContactNo:editedData.mobileNumber,
      Name: editedData.userNewName,
      Password: editedData.password,
      Image:finalImageURL.toString()?finalImageURL.toString():editedData.imageurl?editedData.imageurl:''
    }
   
    // console.log(prvValue)
    if(isEdited){
      const isNameAvail = userDetails.find(item=>item['Name']===updateData.Name)// && editedData.UserID!==item['UserID'])
      if(isNameAvail){
        Count=1
        setEditNameError('UserName Already Existed')
      }
    }

    if(updateData.ContactNo.length!==10){
      Count=1
      setMobileError('Contact Number must be 10 digits')
    }

    if(editedData.password !== editedData.confirmPassword){
      Count=1
      setEditedPasswordError('Passwords do NOT match')
    }
    console.log("Updated data ",updateData,Count);
    if(Count===0){
      
      updateUserData(updateData,(response)=>{
        debugger
        // console.log(response)
        if(response.status===200){
          setShowMessage(true)
          setFinalImage('')
          setIsEdited(false)
          setPrvValue('')
          setEditNameError('')
          setMobileError('')
          setEditedPasswordError('')
          getUserDetails((response)=>{
            if(response.status === 200){
              setUserDetails(response.data) 
              setOpenDialogEdit(false)
            }
          })
        }
      })
      setShowMessage(false)
    }

   
  }

  const handleClose = () => {
    setOpenDialogEdit(false);
    setIsEdited(false)
    setPrvValue('')
    setEditNameError('')
    setMobileError('')
    setEditedPasswordError('')
    setShowMessage(false)
    setOpenMessage(false)
  };




  const handleDisable = (data) => {
    // Add your disable logic here
    console.log("data...",data);
    setOpenMessage(true)   
    // let Id=data.ID?data.ID:0
    setId(data)
    
  };

  const disableUser = () =>{
    if(rowData){
      let body={
        ID:rowData.ID,
        Status:rowData.Status === 1 ? 0: 1
        
      }
      console.log("body",body)
      disableUserData(body,(response)=>{
        if(response.status===200){
          getUserDetails((response)=>{
            if(response.status === 200){
              setUserDetails(response.data) 
              setOpenMessage(false)
            }
          })
        }
      })
    }
  }


  // Function to handle the icon click
  const handlePasswordVisibilityToggle = (index) => {
    setVisiblePasswordIndex(visiblePasswordIndex === index ? null : index);
  };

  return (
    
    <Card variant="outlined" sx={{ p: 3 }}>
      <CardContent>
      <Typography variant="h4" >Add Agent/Supervisor</Typography>          

        <Grid container spacing={2} alignItems="center">
          <Grid container spacing={2} alignItems="center" marginTop={4}>
          <Grid item xs={3}>
              <FormControl fullWidth>
              <InputLabel id="user-type-label">User Type</InputLabel>
                <Select
                  labelId="user-type-label"
                  id="user-type"
                  value={userType}
                  onChange={(e)=>handleUserTypeChange(e,'add')}
                  label="User Type"
                  error={userTypeError ? true : false }
                  // helperText={userTypeError}              

                >
                  {/* <MenuItem value={9}>Deployment</MenuItem> */}
                  <MenuItem value={3}>Supervisor</MenuItem>
                  <MenuItem value={4}>Agent</MenuItem>

                </Select>
                {userTypeError && <Typography color="error" variant="body2"  >{userTypeError}</Typography>}

              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl fullWidth>
                  <TextField
                    id="agent-name"
                    variant="outlined"
                    value={agentName}
                    label="Name"
                    onChange={(e)=>handleAgentNameChange(e,"add")}  
                    error={agentNameError  ? true : false }
                  />
              {agentNameError && <Typography color="error" variant="body2"  >{agentNameError}</Typography>}
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl fullWidth>
                <TextField
                  id="mobile-number"
                  label="Mobile Number"
                  variant="outlined"
                  value={mobileNumber}
                  onChange={(e)=>handleMobileNumberChange(e,"add")}
                  error={mobileNumberError  ? true : false }
                  inputProps={{
                    pattern: "[0-9]{10}",
                    title: "Mobile number must be 10 digits",
                  }}
                  InputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
                {mobileNumberError && <Typography color="error" variant="body2"  >{mobileNumberError}</Typography>}
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl fullWidth>
                <TextField
                  id="userdisplayName"
                  label="User Name"
                  variant="outlined"
                  value={userNewName}
                  onChange={(e)=>handleUsername(e,"add")}
                  error={userNewNameError  ? true : false }                  
                />
              {userNewNameError && <Typography color="error" variant="body2"  >{userNewNameError}</Typography>}

              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl fullWidth>
                {/* <TextField
                  id="userpassword"
                  label="Password"
                  variant="outlined"
                  value={password}
                  onChange={(e)=>handlePasswordChange(e,"add")}
                  error={passwordError  ? true : false }                  
                /> */}

                  <TextField
                  fullWidth
                  name="userpassword"
                  label="Password"
                  onChange={(e)=>handlePasswordChange(e,"add")}
                  value={password}
                  type={showPassword ? 'text' : 'password'}
                  error={passwordError  ? true : false }                  
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
      {passwordError && <Typography color="error" variant="body2"  >{passwordError}</Typography>}


              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl fullWidth>

              <TextField
                  fullWidth
                  name="userpassword"
                  label="Confirm Password"
                  onChange={(e)=>handleConfirmPasswordChange(e,"add")}
                  value={confirmPassword }
                  type={showConfirmPassword ? 'text' : 'password'}
                  error={confirmPasswordError  ? true : false }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                          <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
      {confirmPasswordError && <Typography color="error" variant="body2"  >{confirmPasswordError}</Typography>}



                {/* <TextField
                  id="userpassword"
                  label="Confirm Password"
                  variant="outlined"
                  value={confirmPassword }
                  onChange={(e)=>handleConfirmPasswordChange(e,"add")}
                  error={confirmPasswordError  ? true : false }
                />
                {confirmPasswordError && <Typography color="error" variant="body2"  >{confirmPasswordError}</Typography>} */}

              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl fullWidth>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="contained-button-file"
                  //multiple
                  type="file"
                  onChange={handleImageChange}
                  error={imageError}
                  //helperText={imageError}
                />
                <Grid item xs={3}>
                  <label htmlFor="contained-button-file">
                
                    <Button  color="success" variant="contained" component="span">
                      Upload Image
                    </Button> {image ? image.name : null}
                  </label>
                  {imageError && (
                      <Typography variant="caption" color="error">
                        {imageError}
                      </Typography>
                    )}
                </Grid>
                
                <Typography variant="caption" color="error">
                </Typography>
              </FormControl>
            </Grid>

            <Grid item xs={3} sx={{ height: "100%", marginTop: 2 }}>
          <Button color="error" variant="contained" onClick={handleClear}>
            Clear
          </Button>
          <Button variant="contained" sx={{ marginLeft: 1 }} onClick={handleSubmit}>
            Add
          </Button>
        </Grid>
      </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ marginTop: "1rem" }}
        >
          <Scrollbar>
            <TableContainer 
            >
 
              <Table stickyHeader>
                <TableHead sx={{background: '#f3f3f3' }}>
                  <TableRow sx={{background: '#f3f3f3' }}>
                    <TableCell>SNo</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>User Type</TableCell>
                    <TableCell>Mobile Number</TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Password</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>Created On</TableCell>
                    <TableCell>Created By</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Your table rows go here */}
                  {userDetails.map((data, index) => (
                    <TableRow key={index}
                    // style={{ backgroundColor: data.Status === 0 ? '#F5B7B1' : 'inherit' }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{data.ID}</TableCell>
                      <TableCell>{data.displayname}</TableCell>
                      <TableCell>{data.UserType === 4 ? "Agent" :data.UserType === 3 ? "Supervisor" : data.UserType ===  9 ? 'Deployment':'' }</TableCell>
                      <TableCell>{data.ContactNo}</TableCell>
                      <TableCell>{data.Name}</TableCell>

                        <TableCell>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2" style={{ marginRight: 8 }}>
                        {visiblePasswordIndex === index ? data.Password : '••••••••'}
                      </Typography>
                      <IconButton
                        onClick={() => handlePasswordVisibilityToggle(index)}
                        edge="end"
                      >
                        {visiblePasswordIndex === index ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </div>
                  </TableCell>



                      {/* <TableCell>{data.Password}</TableCell> */}
                      
                      {data.Image ? (<TableCell>
                        <img
                          src={data.Image}
                          alt="User Image"
                          style={{
                            width: "100px",
                            height: "100px",
                          }}
                        />
                      </TableCell>) : (
                        <TableCell>Image Not Available</TableCell>
                      )}
                      <TableCell>{data.CreatedOn}</TableCell>
                      <TableCell>{data.CreatedBy}</TableCell>
                      <TableCell>
                        <Grid container spacing={2}>
                         <Grid item>
                           <Button
                            disabled={data.Status === 0}
                            style={{
                              backgroundColor: data.Status === 0 ? "gray" : "",
                              color: "white"
                            }}
                            variant="contained" 
                            onClick={() => handleEdit(data)}>
                              Edit
                            </Button>
                          </Grid>
                          
                          <Grid item>
                            <Button

                              variant="contained"
                              style={{
                                backgroundColor: data.Status === 1 ? "red" : "green",
                                color: "white"
                              }}
                              onClick={()=>handleDisable(data)}
                            >
                              {data.Status === 1 ? "Disable" : "Enable"}
                            </Button>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Grid>

        <Dialog open={openDialogEdit} onClose={handleClose} fullWidth={fullWidth} maxWidth={maxWidth}>
        <DialogContent>
       
        <Grid container spacing={2} alignItems="center">
            <Grid container spacing={2} alignItems="center" marginTop={4}>
              
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="user-type-label">User Type  </InputLabel>
                  <Select
                    labelId="user-type-label"
                    id="user-type"
                    value={editedData.userType}
                    onChange={(e)=>handleUserTypeChange(e,'edit')}
                    label="User Type"
                    disabled={editedData.userType === 9}
                  >
                  {editedData.userType !== 3 && editedData.userType !== 4 && (
                        <MenuItem value={9}>Deployment</MenuItem>
                      )}
                    <MenuItem value={3}>Supervisor</MenuItem>
                    <MenuItem value={4}>Agent</MenuItem>
                  </Select>
                </FormControl>
              </Grid>



              <Grid item xs={3}>
                <FormControl fullWidth>
                  <TextField
                    id="agent-name"
                    variant="outlined"
                    value={editedData.agentName}
                    label="Name"
                    onChange={(e)=>handleAgentNameChange(e,"edit")}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl fullWidth>
                  <TextField
                    id="mobile-number"
                    label="Mobile Number"
                    variant="outlined"
                    value={editedData.mobileNumber}
                    onChange={(e)=>handleMobileNumberChange(e,"edit")}
                    error={mobileError ? true : false}
                    // inputProps={{
                    //   pattern: "[0-9]{10}",
                    //   title: "Mobile number must be 10 digits",
                    // }}
                    // InputProps={{
                    //   inputMode: "numeric",
                    //   pattern: "[0-9]*",
                    // }}
                  />
              {mobileError && <Typography color="error" variant="body2"  >{mobileError}</Typography>}

                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl fullWidth>
                  <TextField
                    id="userdisplayName"
                    label="User Name"
                    variant="outlined"
                    value={editedData.userNewName}
                    onChange={(e)=>handleUsername(e,"edit")}
                    error={editNameError ? true : false}
                  />
                  {editNameError && <Typography color="error" variant="body2"  >{editNameError}</Typography>}

                </FormControl>
                {/* {editNameError && (<Typography color='error' fontSize={12}>{editNameError}</Typography>)} */}
              </Grid>

              <Grid item xs={3}>
                <FormControl fullWidth>
                  <TextField
                    id="userpassword"
                    label="Password"
                    variant="outlined"
                    value={editedData.password}
                    onChange={(e)=>handlePasswordChange(e,"edit")}
                    error={editedPasswordError ? true : false}
                  />
              {editedPasswordError && <Typography color="error" variant="body2"  >{editedPasswordError}</Typography>}
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl fullWidth>
                <TextField
                  id="confirmPassword"
                  label="Confirm Password"
                  variant="outlined"
                  value={editedData.confirmPassword}
                  onChange={(e) => handleConfirmPasswordChange(e, "edit")}
                  error={editedPasswordError ? true : false}
                />
              {editedPasswordError && <Typography color="error" variant="body2"  >{editedPasswordError}</Typography>}
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="contained-button-file-two"
                    type="file"
                    onChange={handleEditedImageChange}
                  />
                  <label htmlFor="contained-button-file-two">
                    <Button color="success" variant="contained" component="span">
                      Upload Image
                    </Button> {""}
                    {editedData.image?editedData.image.name?editedData.image.name:editedData.image:''}
                  </label>
                </FormControl>
              </Grid>

              <Grid item xs={12} sx={{ height: "100%",textAlign:'right'}}>
                <Button color="error" variant="contained" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="contained" sx={{ marginLeft: 1 }} onClick={handleUpdated}>
                  Save
                </Button>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center" marginTop={4} marginLeft={1}> 
                 {showMessage && (<Typography color='#3f51b5'>Details Updated Successfully!!</Typography>)}
            </Grid>
          </Grid>
          


        </DialogContent>
      </Dialog>

      {/* <Dialog
        open={openMessage}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: '#f5f5f5',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '10px',
          },
          '& .MuiTypography-root': {
            fontWeight: 'bold',
            marginBottom: '20px',
          }
        }}
      >
        <Typography>Do You Want to Disable User?</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Grid container spacing={2} paddingTop={2} gap={2}>
            <Button onClick={disableUser} variant="contained" color="primary">Ok</Button>
            <Button onClick={handleClose} variant="outlined" color="error">Close</Button>
          </Grid>
        </Box>
      </Dialog> */}

      <Dialog 
  open={openMessage}
  onClose={handleClose}      >
            <DialogContent>
              <Stack
                //  direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ maxWidth: 350, textAlign: "center" }}
              >
                <Iconify
                  icon={"eva:alert-triangle-outline"}
                  sx={{ fontSize: 92, color: "#ffb2a3" }}
                />
                <Typography sx={{ color: "gray" }} variant="h5">
                  {" "}
                  Are you sure you want to {rowData.Status === 1 ? "Disable" : "Enable"}  the user :  {rowData.displayname}?
                </Typography>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={handleClose}
                color="error"
              >
                No
              </Button>
              <Button variant="contained"  onClick={disableUser}>
                Yes
              </Button>
            </DialogActions>
          </Dialog>

      </CardContent>
    </Card>

  );
};

export default AddAgent;