import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Stack,
  IconButton,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Button,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/Iconify';
import { addClient, getTimeZonesRegister ,getClients} from 'src/api/register';


export default function RegisterForm() {
  const navigate = useNavigate();

  const [clientName, setClientName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [timezones, setTimezones] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState('');
  // const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [captcha, setCaptcha] = useState('');
  const [confirmCaptcha, setConfirmCaptcha] = useState('');

  const [FieldError,setError] = useState(false);
  const [userDetails,setUserDetails] = useState([]);
  const [duplicateVal,setDuplicateVal] = useState(false);
  const [duplicateEmailVal,setDuplicateEmailVal] = useState(false);
  const [passwordFieldError,setPasswordError] = useState(false);


  useEffect(() => {
    getTimeZonesRegister((error, response) => {
      if (error) {
        console.error('Error fetching time zones:', error);
      } else if (response.status === 200) {
        setTimezones(response.data);
      }
    });

    generateCaptcha();
  }, []);

  useEffect(() => {
    getClients((response) => {
        if (response.status === 200) {
            setUserDetails(response.data);
        } else {
            console.error("Unexpected status code:", response.status);
        }
    });
}, []);

  const generateCaptcha = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setCaptcha(result);
  };

  const handleClientName = (e) => setClientName(e.target.value);
  const handleClientMail = (e) => setEmail(e.target.value);
  const handleClientPhoneNumber = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    setPhoneNumber(numericValue);
  };
    const handleTimeChange = (event) => setSelectedTimeZone(event.target.value);
  // const handleUserName = (event) => setUserName(event.target.value);
  const handlePassword = (event) => setPassword(event.target.value);
  
  const handleConfirmPassword = (event) => setConfirmPassword(event.target.value);
  const handleCaptcha = (event) => setConfirmCaptcha(event.target.value);
  
  // const handlePassword = (event) => {
  //   const newPassword = event.target.value;
    
  //   if (newPassword.length >= 8) {
  //     setPassword(newPassword);
  //     setPasswordError(false);
  //     // Password meets length requirement, proceed with setting it
  //   } else {
  //     setPasswordError(true)
  //     // Handle password length requirement not met (e.g., show error message)
  //   }
  // };
  

  const handleSubmit = () => {
    setError(true);



    // console.log("data", clientName, email, phoneNumber, selectedTimeZone, userName, password, confirmPassword);
    if( !clientName || !email || !selectedTimeZone || !password || captcha != confirmCaptcha || !confirmPassword || password != confirmPassword){
      console.log("error")
    }
    else{
      setDuplicateVal(false)
      setPasswordError(false)
      const data = {
        Name: email,
        Email:email,
        ContactNo:phoneNumber,
        TimezoneId:selectedTimeZone,
        ClientType:selectedTimeZone === 2 ? "INDIA":"US",
        displayname:clientName,
        Password:password,
        ClientStatus: "Trail",
        ActiveDuration: 30,
        VerificationStatus:0,
        UserType:2
      }
  
      const isNameAvail = userDetails.some(item=>item['displayname']===data.displayname)
      const isEmailAvail = userDetails.some(item=>item['Email']===data.Email)
      if(isNameAvail){
        setDuplicateVal(true)
        return
      }
      if(isEmailAvail){
        setDuplicateEmailVal(true)
        return
      }

    if (password.length <= 8) {
      setPasswordError(true)
      return
      // Password meets length requirement, proceed with setting it
    }
       addClient(data, (response) => {
        if (response.status === 200) {
      //     navigate('/dashboard', { replace: true });
             setError(true);
        }
      });
    }

  };

  return (
    <>
      <Typography variant="subtitle2" sx={{ my: 2 }}>
        Register Info
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="clientName"
            label="Client/Company Name"
            onChange={handleClientName}
            value={clientName}
            error={FieldError  && !clientName || FieldError && duplicateVal}
          />
          {FieldError && !clientName && <FormHelperText error>Please insert Client Name</FormHelperText>} 
          {FieldError && duplicateVal && <FormHelperText error>Client Name is Already Exist</FormHelperText>} 

        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="email"
            label="Email"
            onChange={handleClientMail}
            value={email}
            error={FieldError  && !email || FieldError && duplicateEmailVal}

          />
          {FieldError && !email && <FormHelperText error>Please insert Email</FormHelperText>} 
          {FieldError && duplicateEmailVal && <FormHelperText error>Email is Already Exist</FormHelperText>} 

        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="phoneNumber"
            label="Phone Number"
            onChange={handleClientPhoneNumber}
            value={phoneNumber}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="timeZone-label" 
            error={FieldError  && !selectedTimeZone}
            >Time Zone</InputLabel>
            <Select
              label="Time Zone"
              labelId="timeZone-label"
              value={selectedTimeZone}
              onChange={handleTimeChange}
              error={FieldError  && !selectedTimeZone}

            >
              {timezones.map((option) => (
                <MenuItem key={option.Id} value={option.Id}>
                  {option.TimeZone}
                </MenuItem>
              ))}
            </Select>
            {FieldError && !selectedTimeZone && <FormHelperText error>Please Select Time Zone</FormHelperText>} 

            
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ my: 2 }}>
        {/* <Grid item xs={12}>
          <TextField
            fullWidth
            name="clientUsername"
            label="Username"
            onChange={handleUserName}
            value={userName}
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="clientPassword"
            label="Password"
            onChange={handlePassword}
            value={password}
            type={showPassword ? 'text' : 'password'}
            error={FieldError  && !password || FieldError && passwordFieldError}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
         {FieldError && !password && <FormHelperText error>Please insert Password</FormHelperText>} 
         {FieldError && passwordFieldError && <FormHelperText error>Password Contains Atlease 8 characters</FormHelperText>} 

        </Grid>
        <Grid item xs={12} sm={6}>
  <TextField
    fullWidth
    name="confirmPassword"
    label="Confirm Password"
    onChange={handleConfirmPassword}
    value={confirmPassword}
    type={showConfirmPassword ? 'text' : 'password'}
    error={FieldError && (!confirmPassword || password !== confirmPassword) || FieldError && passwordFieldError}
    helperText={
      FieldError &&
      (!confirmPassword ? "Please insert Confirm Password" : password !== confirmPassword ? "Password should match" : "")
    }
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton edge="end" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
            <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
    {FieldError && passwordFieldError && <FormHelperText error>Password Contains Atlease 8 characters</FormHelperText>} 

</Grid>

      </Grid>
      <Grid container spacing={2} sx={{ my: 2 }}>
        <Grid item xs={12} sm={8}>
          <TextField
            fullWidth
            name="captchaInput"
            label="Enter Captcha"
            onChange={handleCaptcha}
            value={confirmCaptcha}
            error={FieldError  && !confirmCaptcha}
          />
        {FieldError && !confirmCaptcha && <FormHelperText error>Please insert Valid Captcha</FormHelperText>} 

        </Grid>
        <Grid item xs={12} sm={4} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1" sx={{ color: 'green', fontWeight: 'bold' }}>
            {captcha}
          </Typography>
          <IconButton onClick={generateCaptcha}>
            <Iconify icon="eva:refresh-fill" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Button color="error" variant="outlined" sx={{ width: '100%' }} onClick={() => navigate('/home')}>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ width: '100%' }}
            onClick={handleSubmit}
          >
            Submit
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}
