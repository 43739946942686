import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Tabs,
  Tab
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import client from 'src/api/clients';
import { ClientForm, ClientList } from 'src/sections/client';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ClientDetails from 'src/sections/client/ClientDetails';
// ----------------------------------------------------------------------

export default function Clients() {
  
  const [compareData,setCompareData] = useState([])
  const [moremenu, setOpenMenu] = useState({ isOpen: false, id: null });

  const [clietns, setClients] = useState({
    isLoad: false,
    all: [],
    active: [],
    inActive: [],
    auditAll: [],
    auditActive: [],
    auditInActive: [],
    nonAuditAll: [],
    nonAudiActive: [],
    nonAuditInActive: [],
  })

  useEffect(() => {
    client.getClients((response) => {
      if (response.status == 200) {
        const active = []
        const inActive = []
        const auditAll = []
        const auditActive = []
        const auditInActive = []
        const nonAuditAll = []
        const nonAudiActive = []
        const nonAuditInActive = []
        response.data.forEach((row) => {
          if (row.Status === 1)
            active.push(row)
          else if (row.Status === 0)
            inActive.push(row)

          // Audit based audits
          if (row.IsAgentBased === 1) {
              auditAll.push(row)
              if (row.Status === 1)
                auditActive.push(row)
              else if (row.Status === 0)
                auditInActive.push(row)
          } else if (row.IsAgentBased === 0) {
              nonAuditAll.push(row)
              if (row.Status === 1)
                nonAudiActive.push(row)
              else if (row.Status === 0)
                nonAuditInActive.push(row)
          }
        })

        setClients({ 
          ...clietns,
          all: response.data,
          active: active,
          inActive: inActive,
          auditAll: auditAll,
          auditActive: auditActive,
          auditInActive: auditInActive,
          nonAuditAll: nonAuditAll,
          nonAudiActive: nonAudiActive,
          nonAuditInActive: nonAuditInActive,
        })

      }
    })
  }, [clietns.isLoad])

  const reloadClientsData = () => setClients({...clietns, isLoad: !clietns.isLoad})


  const [dialog, setOpen] = useState({ isOpen: false, id: null });
  function handleDialog(id=null) {
    // console.log('dialog', dialog)
    setOpen({...dialog, isOpen: true, id: id})
  }
  const [value, setValue] = useState('one');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function handleMoremenu(id=null) {
   
    setOpenMenu({...moremenu, isOpen: true, id: id})
  }


  return (
    <Page title="User">
      <Container maxWidth="xl">
        <ClientForm  total={clietns.all} dialogData={dialog} setOpen={setOpen} reloadClientsData={reloadClientsData}/>
        <ClientDetails dialogData={moremenu} setOpen={setOpenMenu} reloadClientsData={reloadClientsData}/>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Clients 
          </Typography>
          <Button variant="contained" onClick={() => handleDialog()} component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
            Add Client
          </Button>
        </Stack>
        <Card>
          <TabContext value={value}>
            <TabList
                onChange={handleChange}
                aria-label="wrapped label tabs example"
                sx={{ borderBottom: "1px solid #ebebeb", backgroundColor: "#f3f3f3" }}
              >
              </TabList>
              <TabPanel value="one" index={0} sx={{ p: 0}}>                
                <ClientList total={clietns.all} active={clietns.active} inactive={clietns.inActive} handleDialog={handleDialog} reloadClientsData={reloadClientsData} handleMoremenu={handleMoremenu}/>
              </TabPanel>
          </TabContext>
        </Card>
      </Container>
    </Page>
  );
}
