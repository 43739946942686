// component
import Iconify from '../../components/Iconify';
// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfigInd = [
  {
    title: 'Dashboard',
    path: '/',
    icon: getIcon('eva:pie-chart-2-fill'),
    isHideInXs: false,
    positiveAccess: [],
    positiveUserRoles: [],
    negativeUserRoles: [37],
    negativeAccess: [863],
  },
  {
    title: 'Dashboard',
    path: '/Dashboardapp',
    icon: getIcon('eva:pie-chart-2-fill'),
    isHideInXs: false,
    positiveAccess: [863],
    positiveUserRoles: [],
    negativeUserRoles: [],
    negativeAccess: [],
  },

  {
    title: 'Add Camera',
    path: '/add-client-camera',
    icon: getIcon('mdi:camera'),
    isHideInXs: false,
    positiveAccess: [],
    positiveUserRoles: [],
    negativeUserRoles: [37],
    negativeAccess: [],
  },
  {
    title: 'Analytics',
    path: '/client-analytics',
    icon: getIcon('eva:color-palette-outline'),
    isHideInXs: false,
    positiveAccess: [],
    positiveUserRoles: [],
    negativeUserRoles: [37],
    negativeAccess: [],
  },
//   {
//     title: 'Live',
//     path: '/cameras-live',
//     icon: getIcon('eva:video-outline'),
//     isHideInXs: false,
//     positiveAccess: [],
//     positiveUserRoles: [],
//     negativeUserRoles: [9,37],
//     negativeAccess: [863,855,917,883],
//   },
//   {
//     title: 'Video Archives',
//     path: '/video_archives',
//     icon: getIcon('eva:archive-outline'),
//     isHideInXs: false,
//     positiveAccess: [],
//     positiveUserRoles: [],
//     negativeUserRoles: [9,37],
//     negativeAccess: [863,855,917,883],
//   },
  {
    title:'Audits',
    path: '/audits',
    icon: getIcon('eva:video-off-outline'),
    isHideInXs: false,
    positiveAccess: [],
    positiveUserRoles: [],
    negativeUserRoles: [30,37],
    negativeAccess: [917,883],
  },
//   {
//     title: 'Contacts',
//     path: '/client_contacts',
//     icon: getIcon('eva:smartphone-outline'),
//     isHideInXs: false,
//     positiveAccess: [],
//     positiveUserRoles: [],
//     negativeUserRoles: [37],
//     negativeAccess: [863,855,917,883],
//   },
  {
    title: 'Cameras',
    path: '/cameras',
    icon: getIcon('eva:camera-outline'),
    isHideInXs: false,
    positiveAccess: [],
    positiveUserRoles: [],
    negativeUserRoles: [30, 33, 34, 35],
    negativeAccess: [],
  },

  // {
  //   title: 'Notifications',
  //   path: '/notifications',
  //   icon: getIcon('eva:bell-outline'),
  // isHideInXs: false
  // },


  // {
  //   title: 'Live Audits',
  //   path: 'live-audits',
  //   icon: getIcon('eva:shake-outline'),
  //   isHideInXs: true,
  //   positiveAccess: [],
  //   positiveUserRoles: [],
  //   negativeUserRoles: [],
  //   negativeAccess: [733, 790],
  // },  
  {
    title: 'Security Reports',
    path: 'reports',
    icon: getIcon('eva:book-outline'),
    isHideInXs: true,
    positiveAccess: [733],
    positiveUserRoles: [],
    negativeUserRoles: [],
    negativeAccess: [],
  },
  {
    title: 'Employee Reports',
    path: 'reportsinfc',
    icon: getIcon('eva:book-outline'),
    isHideInXs: true,
    positiveAccess: [917],
    positiveUserRoles: [],
    negativeUserRoles: [],
    negativeAccess: [],
  },
  {
    title: 'Events Report',
    path: 'jfw-reports',
    icon: getIcon('eva:book-outline'),
    isHideInXs: true,
    positiveAccess: [790],
    positiveUserRoles: [],
    negativeUserRoles: [],
    negativeAccess: [],
  },
  {
    title: 'Head Count Inputs',
    path: 'head-count-input',
    icon: getIcon('eva:book-outline'),
    isHideInXs: true,
    positiveAccess: [797],
    positiveUserRoles: [30, 2, 7],
    negativeUserRoles: [],
    negativeAccess: [],
  },
  {
    title: 'License Plates',
    path: 'license-plate',
    icon: getIcon('eva:car-outline'),
    isHideInXs: true,
    positiveAccess: [692],
    positiveUserRoles: [],
    negativeUserRoles: [],
    negativeAccess: []
  },
  {
    title: 'Head Count Report',
    path: 'head-count-report',
    icon: getIcon('eva:funnel-outline'),
    isHideInXs: true,
    positiveAccess: [797],
    positiveUserRoles: [30, 2, 7],
    negativeUserRoles: [],
    negativeAccess: []
  },
  {
    title: 'Shift Timings',
    path: 'shifttime',
    icon: getIcon('eva:people-outline'),
    isHideInXs: true,
    positiveAccess: [768],
    positiveUserRoles: [],
    negativeUserRoles: [],
    negativeAccess: [],
  },
  {
    title: 'Report',
    path: '/neterwala',
    icon: getIcon('eva:people-outline'),
    isHideInXs: false,
    positiveAccess: [765],
    positiveUserRoles: [],
    negativeUserRoles: [],
    negativeAccess: [],
  },
  {
    title: 'Dashboard',
    path: '/DashboardMdPSIT',
    icon: getIcon('eva:pie-chart-2-fill'),
    isHideInXs: false,
    positiveAccess: [37],
    positiveUserRoles: [37],
    negativeUserRoles: [],
    negativeAccess: [],
  },
  {
    title: 'Reports',
    path: '/mdReport',
    icon: getIcon('eva:file-text-outline'),
    isHideInXs: false,
    positiveAccess: [37],
    positiveUserRoles: [37],
    negativeUserRoles: [],
    negativeAccess: [],
  },
  {
    title: 'Shows',
    path: '/addShow',
    icon: getIcon('eva:film-outline'),
    isHideInXs: false,
    positiveAccess: [883],
    positiveUserRoles: [],
    negativeUserRoles: [],
    negativeAccess: [],
  },
  {
    title: 'Reports',
    path: '/mohiniReport',
    icon: getIcon('eva:file-text-outline'),
    isHideInXs: false,
    positiveAccess: [883],
    positiveUserRoles: [],
    negativeUserRoles: [],
    negativeAccess: [],
  }

];

export default navConfigInd;

