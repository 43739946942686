import axios from '../axios'
import { getURL, jsonToParam } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';

// get cameras data
const getCamerasList = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('devices')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const getClientList = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('client-info/get-clients')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const getServerList = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('/servers')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const getTimezoneList = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('/timezone?status=1')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}
const getArchiveTimings = (body,callback) => {
    axios({
        method: 'get',
        url: getURL('/archive-monitor-timings?deviceid='+body),
        //data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

// const getClientList = (callback) =>{
//     axios({
//         method: 'get',
//         url: `${getURL('get-clients')}`,
//         validateStatus: (status) => handleResponse(status)
//     }).then((response) => callback(response))
// }

// const getSavedAnalytics = (callback) => {
//     axios({
//         method: 'get',
//         url: `${getURL('analytics-settings')}`,
//         validateStatus: (status) => handleResponse(status)
//     }).then((response) => callback(response))
// }

const getSavedAnalytics = (clientId, callback) => {
    axios({
      method: 'get',
      url: `${getURL('analytics-settings')}?client_id=${clientId}`,
      validateStatus: (status) => handleResponse(status),
    }).then((response) => callback(response));
  };
  


const getAnalyticsMain = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('analytics-main')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const getAnalyticsSub = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('analytics-sub')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const setAnalytics = (callback, body) => {
    axios({
        method: 'post',
        url: getURL('/analytics-settings/add'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const setAddDevice = (callback, body) => {
    axios({
        method: 'post',
        url: getURL('/devices/add'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const postArchiveTimings = (body,callback) => {
    debugger
    //console.log(callback)
    axios({
        method: 'put',
        url: getURL('/archive-monitor-timings/update'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const postMonitoringTimings = (body,callback) => {
    //console.log(callback)
    axios({
        method: 'put',
        url: getURL('/monitor-timings/update'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}


const getMonitoringTimings = (body,callback) => {
    axios({
        method: 'get',
        url: getURL('/monitor-timings/by-device?device_id='+body),
        //data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const downloadVideo = async (videoUrl, callback) => {
    debugger
    try {
       // const downloadUrl = getURL('recordings/download?url='+videoUrl)
        const response = await axios({
            method: 'get',
            url: getURL('recordings/download?url='+videoUrl),
            responseType:'blob',
            validateStatus: (status) => {
                // if(status===200){
                //     window.location.href = downloadUrl;
                // }
                return status >= 200 && status < 300;
            },
        });
        if(response.status===200){
            window.location.href = getURL('recordings/download?url='+videoUrl);
        }
        callback(response);
    } catch (error) {
        console.error('Error downloading video:', error);
    }
};

//Email time setup
const postEmailTime = (data,callback) => {
    axios({
        method: 'post',
        url: `${getURL('/client/add-or-update-client-email-timings')}`,
        data: data,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}


const getEmailClients = (data, callback) => {
    const { client_id } = data
    axios({
      method: 'get',
      url: `${getURL('client/client-email-timings')}?client_id=${client_id}`,
      validateStatus: (status) => handleResponse(status),
    }).then((response) => callback(response));
  };



export { downloadVideo,getArchiveTimings,getTimezoneList,postMonitoringTimings,getCamerasList, getSavedAnalytics, getAnalyticsMain, getAnalyticsSub, setAnalytics,getClientList,setAddDevice ,getServerList,postArchiveTimings,getMonitoringTimings,postEmailTime,getEmailClients }

// export { getCamerasList, getSavedAnalytics, getAnalyticsMain, getAnalyticsSub, setAnalytics ,getClientList,setAddDevice ,getServerList}