import { useEffect, useState } from 'react';
import {
  Checkbox,
  Container,
  Stack,
  Typography,
  CircularProgress,
  OutlinedInput,
  InputAdornment,
  Toolbar,
  Box,
  Card,
  Autocomplete,
  TextField,
  Chip,
  Button,
  createFilterOptions,
} from '@mui/material';
// components
import Page from '../components/Page';
import { AuditList } from '../sections/auditlist';
// mock
import { styled } from '@mui/material/styles';
import useAuditList from 'src/api/useAuditList';
import Iconify from 'src/components/Iconify';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationAlert from 'src/utils/NotificationAlert';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import dayjs from 'dayjs';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// import CloseIcon from '@mui/icons-material/Close';
// import { useState } from 'react';
// ----------------------------------------------------------------------

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  maxWidth: 200,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const CustomBox = styled(Stack)(({ theme }) => ({
  border: `1px dashed rgba(145, 158, 171, 0.16)`,
  padding: theme.spacing(1),
  borderRadius: 8
}));

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  // padding: theme.spacing(0, 1, 0, 3),
}));

export default function ListAudits({userType, clientId ,IsAgentBased}) {
  const {
    alldata,
    setSearch,
    loadMoreAudits,
    filterAudits,
    updateToClient,
    isEscalating,
    open,
    setOpen,
    cameras,
    analytics,
    fromDate,
    toDate,
    handleFromDate,
    handleToDate,
    detectedType,
    handleDetectedType,
    device,
    handleDevice,
    handleDelete,
    handlerGetFilterData,
    alertStatus,
    handleNotificationBar
  } = useAuditList(userType)

  const [selectedDevices, setSelectedDevices] = useState([]);

  const [activeCameras, setActiveCameras] = useState([])

  const [checkValue,setCheckValue]=useState(false)

  const [openAuto,setOpenAuto]=useState(false)
  
  const handleCamerasChange = (event, value) => {
    //debugger
    let actCams=[];
  if (value.some(obj => obj.hasOwnProperty('selectall'))){
    if(activeCameras.length===cameras.length){
      setActiveCameras([])
      setCheckValue(false)
    }
    else{
      cameras.forEach(item=>{
        actCams.push(item.uid)
      })
      setActiveCameras(actCams)
      setCheckValue(true)
    }
  }
  else{
    let id=value[0].uid;
    if(activeCameras.includes(id)){
      let filterArr = activeCameras.filter(devid => devid !== id);
      //console.log(typeof activeClients[0]);
      setActiveCameras(filterArr);
      if(checkValue){
        setCheckValue(false)
      }
    }
    else{
      setActiveCameras((prv)=>[...prv,id])
    }
  }
  };

  useEffect(()=>{
    //debugger
    handleDevice('',activeCameras)
    //console.log(activeCameras)
    if(activeCameras.length===cameras.length && activeCameras.length>0){
      setCheckValue(true)
    }
  },[activeCameras])


  const onFilterAudits = (event) => {
    setSearch(event.target.value)
    filterAudits(event.target.value)
  }

  const handleFilterOptions = (options, params) => {
    debugger
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    
    // Separate selected and unselected options
    const selectedOptions = filtered.filter(option => activeCameras.includes(option.uid));
    const unselectedOptions = filtered.filter(option => !activeCameras.includes(option.uid));
    
    // Sort options: selected options first, then unselected
    const sortedOptions = [...selectedOptions, ...unselectedOptions];
  
    return [{ title: 'Select All', selectall: true }, ...sortedOptions];
  };

  return (
    <Page title="Audits">
      <Container maxWidth="xl">
        <NotificationAlert 
        open={alertStatus.open}  
        handleClose={handleNotificationBar} 
        title={alertStatus.title} 
        description={alertStatus.description}/>
        { IsAgentBased === 0 ?
        <Typography variant="h4">Audits</Typography> :
        <Typography variant="h4"> Escalated Events</Typography> 
        }
        {Number(userType) === 33 && (
          <Typography sx={{ fontWeight: 800 }}>Note: Monitoring hours - 10 PM to 6 AM</Typography>
        )}        
        
        <Card sx={{ mt: 2}}>


          <Stack direction="row" spacing={2} sx={{ p: 2 }}>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  sx={{width: "100%"}}
                  label="From Date"
                  id="from-date"
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  format="YYYY-MM-DD HH:mm"
                  value={fromDate}
                  onChange={handleFromDate}
                  maxDate={dayjs()}
                />
              </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  sx={{width: "100%"}}
                  label="To Date"
                  id="to-date"
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  format="YYYY-MM-DD HH:mm"
                  value={toDate}
                  onChange={handleToDate}
                  minDate={fromDate}
                  maxDate={dayjs()}
                />
              </LocalizationProvider>
            
            {clientId === 863 &&  
              <Autocomplete
              fullWidth
              // sx={{ minWidth: 300 }}
              multiple
              id="detected-types"
              options={analytics}
              getOptionLabel={(option) => option.Name}
              value={detectedType}
              onChange={handleDetectedType}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.Name}>
                  {option.Name}
                </Box>
              )}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Detected Types"
                  placeholder="Detected Types"
                />
              )}
            />  
          }
              <Autocomplete
              multiple
              fullWidth
              id="camera"
              options={cameras}
              
              //value={(checkValue) ? [{ title: 'Select All', all: true }] : inputValue.filter((camera) => activeCameras.includes(camera.uid))}
               value={[]}

              getOptionLabel={(option) => {
                if (option.selectall) {
                  return 'Select All';
                } else if (option.deviceid && option.devicename) {
                  return `${option.deviceid} ${option.devicename}`;
                } else {
                  return '';
                }
              }}
              
              onChange={handleCamerasChange}
              disableCloseOnSelect={openAuto}
              open={openAuto}
              onOpen={() => setOpenAuto(true)}
              onClose={() => setOpenAuto(false)}
              
              // filterOptions={(options, params) => {
              //   const filter = createFilterOptions();
              //   const filtered = filter(options, params);
              //   return [{ title: 'Select All', selectall: true }, ...filtered];
              // }}

              filterOptions={handleFilterOptions}
              
              renderOption={(props, option, { selected }) => (
                
                <li {...props}>
                  {option.selectall ? (
                    <>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={checkValue}
                        //onChange={() => setCheckValue(!checkValue)}
                      />
                      {option.title}
                    </>
                  ) : (
                    <>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={activeCameras.includes(option.uid)}
                        onChange={() => {
                          const isChecked = activeCameras.includes(option.uid);
                          const newActiveCameras = isChecked
                            ? activeCameras.filter((uid) => uid !== option.uid)
                            : [...activeCameras, option.uid];
                          setActiveCameras(newActiveCameras);
                        }}
                      />
                      {`${option.deviceid} ${option.devicename}`}
                    </>
                  )}
                </li>

              
            )}
              renderInput=
              {(params) => 
                <TextField {...params} label="Camera" onFocus={() => setOpenAuto(true)}/>
              }
            />            
            
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button disabled={!(fromDate || toDate || detectedType.length > 0 || device.length > 0)} 
              onClick={()=>{
                setOpenAuto(false)
                handlerGetFilterData()
              }} 
                variant='contained' size="large">Get</Button>
            </Box>
          </Stack>

            <Box sx={{ backgroundColor: "#ebebeb", p: 1 }}>
              <Box sx={{ textAlign: "right" }}>
                  <SearchStyle
                    // value={filterAudit}
                    onChange={onFilterAudits}
                    sx={{ backgroundColor: "white", mb: 1 }}
                    placeholder="Search..."
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    }
                  />
              </Box>

            {(alldata.isLoad) && (
              <Box sx={{display: "flex",
                alignItems: "center"}}>
                <CircularProgress sx={{ marginLeft: '45%' }} />
              </Box>
            )}            
            
            


            {alldata.filterData.length === 0  && !alldata.isLoad ? (
              <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>
               No data
              </Typography>
              ) : (
                <AuditList audits={alldata.filterData} loadMoreAudits={loadMoreAudits} updateToClient={updateToClient} isEscalating={isEscalating} clientId={clientId} />
              )}

          </Box>

        </Card>
      </Container>
    </Page>
  );
}