import {
    Container,
    Stack,
    Typography,
    CircularProgress,
    OutlinedInput,
    InputAdornment,
    Toolbar,
    Box,
    Card,
    Autocomplete,
    TextField,
    Chip,
    Button,
  } from '@mui/material';
  import {
    
    Table,
   
   
    TableRow,
    TableBody,
    TableCell,
  TableFooter,
    TableContainer,
    TablePagination,
    Link,
  } from '@mui/material';
  // components
  import React, { useState, useEffect } from 'react';
  import { LoadingButton } from '@mui/lab';
  
  import Page from '../components/Page';
  // mock
  // import { styled } from '@mui/material/styles';
  import dayjs from 'dayjs';
  import PlayArrowIcon from '@mui/icons-material/PlayArrow';
  
  import * as XLSX from 'xlsx';
  import { LocalizationProvider } from '@mui/x-date-pickers';
  import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
  import { DatePicker } from '@mui/x-date-pickers/DatePicker';
  import { UserListHead  } from '../../src/sections/@dashboard/user';
  
  import IconButton from '@mui/material/IconButton';
  // import PlayArrowIcon from '@mui/icons-material/PlayArrow';
  
  import SaveAltIcon from '@mui/icons-material/SaveAlt';
  import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
  import Select from '@mui/material/Select';
  import MenuItem from '@mui/material/MenuItem';
  // import Loader from 'react-loader-spinner';
  // import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
  import axios from "axios";
  
  import FormControl from '@mui/material/FormControl';
  import InputLabel from '@mui/material/InputLabel';
  import ClearIcon from '@mui/icons-material/Clear';
  
  import Dialog from '@mui/material/Dialog';
  import DialogContent from '@mui/material/DialogContent';
  import DialogTitle from '@mui/material/DialogTitle';
  
  // import { common } from '@mui/material/colors';
  // import { values } from 'lodash';
  // import { useRef } from 'react';
  // import { TimePicker } from '@mui/x-date-pickers/TimePicker';
  
  
  // const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  //   maxWidth: 200,
  //   transition: theme.transitions.create(['box-shadow', 'width'], {
  //     easing: theme.transitions.easing.easeInOut,
  //     duration: theme.transitions.duration.shorter,
  //   }),
  //   '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  //   '& fieldset': {
  //     borderWidth: `1px !important`,
  //     borderColor: `${theme.palette.grey[500_32]} !important`,
  //   },
  // }));
  
  
  
  // const EmployeeStatus = ['Entry','Exit'];
  // const formatTime = (timeString) => {
  //   const time = new Date(`1970-01-01T${timeString}`);
  //   return time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  // };
  
  
  
  
  export default function NetrawalaEntry() {
    const [locationNames, setLocationNames] = useState([]); // Initialize as an empty array
    const [selectedLocation, setSelectedLocation] = useState([]); // Initial value set to null
    const [selectedLocationId, setSelectedLocationId] = useState(null); // New state to stor
    const [apiData, setApiData] = useState(null); // New state for storing API data
    const [selectedShiftTypes, setSelectedShiftTypes] = useState([]); // Initialize as an empty array
    const [shiftTypesNames, setShiftTypesNames] = useState([]); // Initialize as an empty array
    const [fromTime, setFromTime] = useState(null);
    const [toTime, setToTime] = useState(null);
    // const [selectedEmployeeStatus, setEmployeeStatus] = useState(null);
  
    // const [plusTime, setPlusMin] = useState(null);
    // const [minusTime, setMinuMin] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [responseData, setResponseData] = useState([]);
  
    const [selectedGetLocation, setSelectedGetLocation] = useState([]); // Initial value set to null
    const [selectedShift, setSelectedGetShift] = useState([]); // New state to stor
  
    // const [locationError, setLocationError] = useState(false);
    const [displayDate,setDisplayDate] = useState(null);
    // const [entryClip,setEntrycllip] = useState(null);
    // const [exitClip,setExitclip] = useState(null);
  
    const [openVideoPopup, setOpenVideoPopup] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [dailogTitle,setDailogTitle] = useState('');
    // Add error states for each field
  const [locationError, setLocationError] = useState(false);
  const [shiftTypeError, setShiftTypeError] = useState(false);
  const [fromDateError, setFromDateError] = useState(false);
  // Add more error states if needed
  const [setEntryvideo, setEntryvideoURL] = useState(null); // New state to stor
  const [setExitvideo, setExitvideoURL] = useState(null); // New state to stor
  const [loading, setLoading] = useState(false);
  const [dateValidate,setValidate] = useState(false);
  const [totalEntryCount, setTotalEntryCount] = useState(0);
  const [totalExitCount, setTotalExitCount] = useState(0);
  const [entryExitDifference, setEntryExitDifference] = useState(0);

  let rowSpanApplied = false;
  let rowSpanAppliedExit =false;
    const handleFromDateChange = (date) => {
      setFromDate(date);
    };

  

  
function deepCopy(obj) {
    if (typeof obj !== 'object' || obj === null) {
        return obj; // If obj is not an object, return it as is
    }
    let copy = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            copy[key] = deepCopy(obj[key]); // Recursively copy nested objects/arrays
        }
    }
    return copy;
  }

  
  const mergeRows = (data) => {
    const merges = [];
    data.forEach((row, index) => {
      Object.keys(row).forEach((key, col) => {
        if (typeof (row[key]) === "object" && row[key].hasOwnProperty("rowSpam") && row[key]["rowSpam"]) {
        //   console.log(index, col, index + (parseInt(row[key]["rowSpam"])))
          merges.push({
            s: { r: index + 1, c: col },
            e: { r: index + (parseInt(row[key]["rowSpam"])), c: col },
          });
        }
      });
    });
    return merges;
  };
  

 
const exportToExcel = () => {
  if (!responseData || responseData.length === 0) {
    alert('No data to Export.');
    return;
  }

  const sheetName = selectedGetLocation + '_' + selectedShift;
  const headers = ['S.No', 'Date', 'Location Name', 'Shift Type', 'Vest Type', 'Entry Count', 'Exit Count', 'Entry Video', 'Exit Video'];

  const formattedHeaders = headers.map(header =>
    header
      .replace(/_/g, ' ')
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase())
  );
  const indexWithData = responseData.findIndex((item) => item.entry_video !== undefined && item.exit_video !== undefined);

  const formattedData = responseData.map((item, index) => {
    const isLastRow = index === responseData.length - 1;
    const entryVideoValue = isLastRow ? item.entry_video : '';
    const exitVideoValue = isLastRow ? item.exit_video : '';


    return {
      'S.No': (index + 1).toString(),
      'Date': displayDate,
      'Location Name': selectedGetLocation,
      'Shift Type': selectedShift,
      'Vest Type': item.Type,
      'Entry Count': (item.entry).toString(),
      'Exit Count': (item.exit).toString(),
      'Entry Video': index === 0 ? entryVideoValue : '',
      'Exit Video':  index === 0 ? exitVideoValue : '',
    };
  });
  if (indexWithData !== -1) {
    formattedData[0]['Entry Video'] = responseData[indexWithData].entry_video;
    formattedData[0]['Exit Video'] = responseData[indexWithData].exit_video;
  }
  // Additional rows
  const additionalRows = [
    {
      'S.No': '',
      'Date': '',
      'Location Name': '',
      'Shift Type': '',
      'Vest Type': '',
      'Entry Count': '',
      'Exit Count': '',
      'Entry Video': '',
      'Exit Video': '',
    },
    {
      'S.No': '',
      'Date': `Total Number Of People Inside: ${entryExitDifference}`,
      'Location Name': '',
      'Shift Type': '',
      'Vest Type': '',
      'Entry Count': `Total Entry Count: ${totalEntryCount}`,
      'Exit Count': `Total Exit Count: ${totalExitCount}`,
      'Entry Video': '',
      'Exit Video': '',
    },
  ];

  const allRows = [...formattedData, ...additionalRows];

  const ws = XLSX.utils.json_to_sheet(allRows, {
    header: formattedHeaders,
    cellStyles: true,
  });

  const filename = sheetName + '.xlsx';
  const maxColumnWidths = {};
  headers.forEach(header => {
    maxColumnWidths[header] = Math.max(
      20,
      ...allRows.map(row => (row[header] || '').toString().length)
    );
  });
  const columnWidths = headers.map(header => ({
    wch: maxColumnWidths[header],
  }));
  ws['!cols'] = columnWidths;

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName || 'Sheet 1');
  XLSX.writeFile(wb, filename);
};


  
  
 
  const handleButtonClick = (exportType) => {
    // Set button label and color based on export type
    switch (exportType) {
      case 'excel':
      
        exportToExcel();
        break;
      default:
        break;
    }
  };
  
  useEffect(() => {
    if (Array.isArray(responseData) && responseData.length > 0) {
      const newTotalEntryCount = responseData.reduce((sum, entry) => sum + entry.entry, 0);
      const newTotalExitCount = responseData.reduce((sum, entry) => sum + entry.exit, 0);
      const newEntryExitDifference = newTotalEntryCount - newTotalExitCount;

      // Update state values
      setTotalEntryCount(newTotalEntryCount);
      setTotalExitCount(newTotalExitCount);
      setEntryExitDifference(newEntryExitDifference);
    }
  }, [responseData]);

    useEffect(() => {
      const fetchData = async () => {
        const apiUrl = 'http://54.184.152.88:8080/employee-shifts-info/location-names?status=1';
        const AUTH_TOKEN = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`;
        
        try {
          const response = await axios.get(apiUrl, {
            headers: {
              Authorization: AUTH_TOKEN,
            },
          });
  
          const extractedLocationNames = response.data.map(item => item.LocationName);
          setLocationNames(extractedLocationNames);
         
          setApiData(response.data);
        } catch (error) {
          console.error('Error:', error);
        }
      };
   
  
      fetchData();
    }, []);
  
    useEffect(() => {
      const fetchData = async () => {
        if (selectedLocationId !== null ) {
        //   console.log("selected ID",selectedLocationId);
          const apiUrl = 'http://54.184.152.88:8080/employee-shifts-info/shift-names?location_id=';
          const dynamicUrl = `${apiUrl}${selectedLocationId}`;
          const AUTH_TOKEN = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`;
    
          try {
            const response = await axios.get(dynamicUrl, {
              headers: {
                Authorization: AUTH_TOKEN,
              },
            });
            const extractedShiftTypes = response.data.map(item => ({
              ShiftName: item.ShiftName,
              FromTime: item.FromTime, // Add the actual property name from your API response
              ToTime: item.ToTime,     // Add the actual property name from your API response
            }));
            // const extractedShiftTypes = response.data.map(item => item.ShiftName);
            setShiftTypesNames(extractedShiftTypes);
            // console.log("location and shifts",extractedShiftTypes)
            // console.log("location and shifts",extractedShiftTypes.toTime)
  
            setApiData(prevData => {
              // Make sure to merge previous data with the new data
              const newData = [...prevData, ...response.data];
              return newData;
            });
  
            // setApiData(response.data);  // Update apiData after setShiftTypes
          } catch (error) {
            console.error('Error:', error);
          }
        }
      };
    
      // Use data from the first useEffect
      fetchData();
    }, [selectedLocationId]);
    
    // const handlePlay = (url) => {
    //   window.open(url, '_blank');
    // };
    const handlePlay = (url,value) => {
      setVideoUrl(url);
      setDailogTitle(value)
      // console.log(value)
      setOpenVideoPopup(true);
    };
  
    const handleCloseVideoPopup = () => {
      setOpenVideoPopup(false);
      setVideoUrl('');
    };
           
  
    const handleShiftType = (event, value) => {
      
    //   console.log("shift", value);
      setSelectedShiftTypes(value);

    // const shifts = value
    // console.log("target",shifts);  
    // const convertedShifts = value.map(shift => {
    //     if (shift.ToTime === '00:00:00') {
    //         return {...shift, ToTime: '12:00:00'};
    //     } else {
    //         return shift;
    //     }
    // });
    
    // console.log(convertedShifts);
    
    
      // Find the selected shift in the apiData array
      const selectedShiftData = apiData.find(item => item.ShiftName === value);
    
      if (selectedShiftData) {
        const selectedShiftFromTime = selectedShiftData.FromTime;
        const selectedShiftToTime = selectedShiftData.ToTime;
        setFromTime(selectedShiftFromTime)  
        setToTime(selectedShiftToTime)
        // console.log("time picker",selectedShiftData.ToTime);
        // console.log(`From Time: ${selectedShiftFromTime}, To Time: ${selectedShiftToTime}`);
      } else {
        console.error('Selected shift not found in data.');
      }
  ;
    };
  
  
    const handleDetectedType = (event, value) => {
      
      setSelectedLocation(value);
      setSelectedShiftTypes('');
      setFromDate(null);
      setToDate(null)
  
    const selectedLocationObject = apiData.find((location) => location.LocationName === value);
      setSelectedLocationId(selectedLocationObject ? selectedLocationObject.Id : null);
  
    };
  
    // const handleEmployeeActivityType = (event, value) => {
    //   setEmployeeStatus(value);
    //   // console.log("employee status",value);
    //   if(value == 'Entry'){
    //     console.log("employee status",fromTime);
    //     const timePlus30 = dayjs(fromTime, 'HH:mm:ss').add(30, 'minutes').format('HH:mm:ss');
    //     const timeMinus30 = dayjs(fromTime, 'HH:mm:ss').subtract(30, 'minutes').format('HH:mm:ss');
    //     setPlusMin(timePlus30)
    //     setMinuMin(timeMinus30)
    //     // console.log("+ 30",timePlus30)
    //     // console.log("- 30",timeMinus30)
  
  
    //   }if(value == 'Exit'){
    //     console.log("employee status",toTime);
    //     const timePlus30 = dayjs(toTime, 'HH:mm:ss').add(30, 'minutes').format('HH:mm:ss');
    //     const timeMinus30 = dayjs(toTime, 'HH:mm:ss').subtract(30, 'minutes').format('HH:mm:ss');
    //     setPlusMin(timePlus30)
    //     setMinuMin(timeMinus30)
    //     // console.log("+ 30",timePlus30)
    //     // console.log("- 30",timeMinus30)
  
  
    //   }
  
    //   };
      // 1. from time grater then system time and  date is matching show No data
      // 2. from time lessthen system time and date is grater then current date No Data
      // 3. from time grater then system time and date is less then current date show data
  
      const handleSubmit = () => {

        setLoading(true);
        // getTotalCount();

        // console.log('Values',selectedLocation.length,selectedShiftTypes.length,fromDate)
        setResponseData([]);
        setLocationError(false);
        setShiftTypeError(false);
        setFromDateError(false);
        setExitvideoURL('');
        setEntryvideoURL('');
        setTotalEntryCount(0);
        setTotalExitCount(0);
        setEntryExitDifference(0);
        if (selectedLocation.length === 0 || selectedShiftTypes.length === 0 || fromDate === null) {
          // Set error states based on the empty fields
        //   if (selectedLocation.length === 0) {
        //     setLocationError(true);
        //   }
        //   if (selectedShiftTypes.length === 0) {
        //     setShiftTypeError(true);
        //   }
        //   if (fromDate === null) {
        //     setFromDateError(true);
        //   }
        setLoading(false);

           alert('Please fill in all mandatory fields');
          return;
        }
        // console.log('selectedLocation:', selectedLocation);
        // console.log('selectedShiftTypes:', selectedShiftTypes.ShiftName);
        // console.log('selectedFromDate:', fromDate);
        // console.log('selectedToDate:', toDate);
        // if (selectedLocation.length === 0 || selectedShiftTypes.length === 0 || fromDate === null) {
        //   // setErrorMessage('Please fill in all mandatory fields');
        //   alert('Please fill in all mandatory fields');
        //   return;
        // }
        // setErrorMessage('');
  
        // // Check for empty values in mandatory fields
        // if (!selectedLocation || !selectedSsetEntrycllip

        // Extract the FromTime


  
        setSelectedGetLocation(selectedLocation);
        const combinedFromDateTime =  dayjs(fromDate).format('YYYY-MM-DD');
        const displayDateFormate =  dayjs(fromDate).format('MM-DD-YYYY');
        // const entry_video = 'http://54.69.173.158:8070/storage/Videos/6149_SOFTWARE/6149_2024_02_14_19_22_32_195067.mp4';
        // const exit_video = 'http://54.69.173.158:8070/storage/Videos/6149_SOFTWARE/6149_2024_02_14_19_22_32_195067.mp4';
  
        // console.log("From Time",combinedFromDateTime);
              // Get the current system time
        const currentSystemTime = dayjs();

        // Convert combinedFromDateTime to a dayjs object for comparison
        const fromDateTime = dayjs(combinedFromDateTime);

        
        // Check if combinedFromDateTime is greater than the system time
        const isCombinedDateTimeGreaterThanSystemTime = fromDateTime.isAfter(currentSystemTime);

        const fromTime = selectedShiftTypes.FromTime;

        // console.log('From Time validator:', fromTime);
      
        // const combinedSystemTime = dayjs(currentSystemTime.format('YYYY-MM-DD') + ' ' + fromTime);

        //i want to conditions work supparately for time and date

        // 1. from time grater then system time its allowing  
        // 2. from time lessthen system time and date is grater then current date No Date
        // if from time is grater then system time but dat eis less then current date then show data 
        const currentDate = currentSystemTime.format('YYYY-MM-DD');
        // console.log("marking date ",currentSystemTime);

        const combinedDateTime = dayjs(currentDate + ' ' + fromTime);
        
        // console.log("marking date time ",fromDateTime.format('YYYY-MM-DD'));

        if ((combinedDateTime.isAfter(currentSystemTime) ) && (fromDateTime.format('YYYY-MM-DD') === currentSystemTime.format('YYYY-MM-DD'))){
          // setLoading(true);

          setValidate(true);
          // Fetch details or perform other actions here
        //   console.log('Fetching details...');
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          // setLoading(false);
        } else if (isCombinedDateTimeGreaterThanSystemTime) {
          // setLoading(true);

          setValidate(true);
          // Fetch details or perform other actions here
        //   console.log('Fetching details...');
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } 
        else {
          setValidate(false);

                            // if combinedFromDateTime value is grater then system time then  show this table 
        setDisplayDate(displayDateFormate);
        // setEntrycllip(entry_video);
        // setExitclip(entry_video);
        // const combinedToDateTime = dayjs(toDate).format('YYYY-MM-DD');
        // console.log("from date",combinedFromDateTime)
        
        // console.log('combined date', combinedFromDateTime ,combinedToDateTime);
        const apiUrl = `http://54.184.152.88:8080/employee-shifts-info/head-count-info?` +
          `shift_name=${encodeURIComponent(String(selectedShiftTypes.ShiftName))}&` +
          `location_id=${encodeURIComponent( String(selectedLocationId))}&` +
          `fromtime=${encodeURIComponent( String(combinedFromDateTime))}&` +
          `totime=${encodeURIComponent( String(combinedFromDateTime))}`;
      
        // Get the authentication token from local storage
        const AUTH_TOKEN = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`;
        // console.log('AUTH_TOKEN', AUTH_TOKEN);
      
        // Make a GET request to the API endpoint with the Authorization header
        axios.get(apiUrl, {
          headers: {
            Authorization: AUTH_TOKEN,
          },
        })
        .then(response => {
          // Handle the API response
  
        //   console.log('API Response:', response.data);
  
          const modifiedData = response.data.map(item => ({
            ...item,
            Type: item.Type.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()),
          }));
        
         
        //   console.log('data',modifiedData);
          if (modifiedData == []){
            // setResponseData([]);
            // console.log('response modified Empty');

          }
          else{
            setResponseData(modifiedData);        
            setSelectedGetShift(selectedShiftTypes.ShiftName);   
            // console.log('response modified',modifiedData);

          


            modifiedData.map((item, index) => {
                // Print entry_video values to the console
                // console.log(`Entry Video for item ${index + 1}:`, item.entry_video);
                if(item.entry_video){
                    setEntryvideoURL(item.entry_video)
                    // console.log("value",setEntryvideo)
                }
                if(item.exit_video){
                    setExitvideoURL(item.exit_video)
                    // console.log("value",setExitvideo)
                }
              });
              
            //   const newTotalEntryCount = responseData.reduce((sum, entry) => sum + entry.entry, 0);
            //   const newTotalExitCount = responseData.reduce((sum, entry) => sum + entry.exit, 0);
            //   const newEntryExitDifference = newTotalEntryCount - newTotalExitCount;
            //   setTotalEntryCount(newTotalEntryCount);
            //   setTotalExitCount(newTotalExitCount);
            //   setEntryExitDifference(newEntryExitDifference);
     

            //   console.log("TotalEntry Count",newTotalEntryCount,newTotalExitCount,newEntryExitDifference);
  
          }
          setLoading(false);
        })
        .catch(error => {
          // Handle API request errors
          console.error('API Error:', error);
          setLoading(false);
          
        });

        }


        
  
      };

      const TABLE_HEAD = [
        { id: 'Id', label: 'S.No' },
        { id: 'Date', label: 'Date' },
        { id: 'LocationName', label: 'Location Name' },
        { id: 'ShiftName', label: 'Shift Type' },
        { id: 'FromTime', label: 'Vest Type' },
        { id: 'ToTime', label: 'Entry Count' },
        { id: 'Status', label: 'Exit Count' },
        { id: 'EntryVdo', label: 'Entry Video' },
        { id: 'ExitVdo', label: 'Exit Video' },
      ];
     
      // const isButtonDisabled = () => {
      //   return selectedLocation.length === 0 || selectedShiftTypes.length === 0 || fromDate === null || toDate === null ;
      // };
  
    return (
      <Page title="Neterwala">
        <Container maxWidth="xl">      
          <Card sx={{ mt: 2}}>
  
            <Stack direction="row" spacing={2} sx={{ p: 2 }}>
   
                {/* <Autocomplete 
                
        fullWidth
        sx={{ minWidth: 300 ,borderColor:  'red'}}
        
        id="location-names"
        options={locationNames}
        getOptionLabel={(option) => option}
        value={selectedLocation}
        onChange={handleDetectedType}
        
        renderOption={(props, option) => (
        <Box component="li" {...props} key={option}>
        {option}
        </Box>
        )}
        // filterSelectedOptions
        renderInput={(params) => (
          <TextField
          
            {...params}
         
            label="Location Name"
            placeholder="Location Name"
  
          />
   // if value is empty change the border color to red 
      
        )}
      /> */}
  
  <FormControl fullWidth sx={{ minWidth: 300 }}>
    <InputLabel id="location-name-label" sx={{ color: locationError ? 'red' : 'gray' }}>Location Name</InputLabel>
    <Select
      labelId="location-name-label"
      label="Location Name"
      value={selectedLocation}
      onChange={(e) => handleDetectedType(e, e.target.value)}
      error={locationError}
    >
     {locationNames
  .sort((a, b) => a.localeCompare(b))
  .map((option) => (
    <MenuItem key={option} value={option}>
      {option}
    </MenuItem>
  ))}

    </Select>
  </FormControl>
  
  <FormControl fullWidth sx={{ minWidth: 200 }}>
  <InputLabel id="shift-type-label" sx={{ color: shiftTypeError ? 'red' : 'gray' }}>Shift Type</InputLabel>
  <Select
    labelId="shift-type-label"
    label="Shift Type"
    value={selectedShiftTypes}
    onChange={(e) => handleShiftType(e, e.target.value)}
    error={shiftTypeError}
  >
    {/* {shiftTypesNames
      .slice() // Create a copy to avoid modifying the original array
      .sort((a, b) => a.ShiftName.localeCompare(b.ShiftName)) // Sort alphabetically
      .map((option) => (
        <MenuItem key={option.ShiftName} value={option}>
          {`${option.ShiftName} (${formatTime(option.FromTime)} - ${formatTime(option.ToTime)})`}
        </MenuItem>
    
      ))} */}



{
    shiftTypesNames
    .slice()
    .sort((a, b) => a.ShiftName.localeCompare(b.ShiftName))
    .map((option) => {
        // Convert '00:00' to '12:00' if needed
        const fromTime = option.FromTime === '00:00:00' ? '12:00:00' : option.FromTime;
        const toTime = option.ToTime === '00:00:00' ? '12:00:00' : option.ToTime;

        // console.log(`FromTime: ${option.FromTime} | ToTime: ${option.ToTime} | Converted FromTime: ${fromTime} | Converted ToTime: ${toTime}`);

        const formatTimes = (time) => {
          const [hours, minutes] = time.split(':');
          const date = new Date(2000, 0, 1, hours, minutes);
          return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      };
        const fromTimeFormatted = option.FromTime === '00:00:00' ? '12:00 AM' : formatTimes(fromTime);
        const toTimeFormatted = option.ToTime === '00:00:00' ? '12:00 AM' : formatTimes(toTime);

        // return (
        // <MenuItem key={option.ShiftName} value={option}>
        //   {`${option.ShiftName} (${fromTimeFormatted} - ${toTimeFormatted})`}
        // </MenuItem>
        // );



      // const fromTimeFormatted = formatTimes(fromTime);
      // const toTimeFormatted = formatTimes(toTime);

        // return (
        //     <MenuItem key={option.ShiftName} value={option}>
        //         {`${option.ShiftName} (${fromTimeFormatted} - ${toTimeFormatted})`}
        //     </MenuItem>
        // );
        return (
        <MenuItem key={option.ShiftName} value={option}>
        {`${option.ShiftName} (${fromTimeFormatted} - ${toTimeFormatted})`}
        </MenuItem>
        );
    })
}

          
  </Select>
</FormControl>

  
  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
      sx={{ width: '75%' }}
      label="Date"
      value={fromDate}
      onChange={handleFromDateChange}
      InputProps={{
        style: {
          borderColor: fromDateError ? 'red' : 'initial',
          '&:hover': {
            borderColor: fromDateError ? 'red' : 'initial',
          },
          '&.Mui-focused': {
            borderColor: fromDateError ? 'red' : 'initial',
          },
        },
      }}
    />
  </LocalizationProvider> */}
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <FormControl fullWidth sx={{ width: '75%', '& fieldset': { borderColor: fromDateError ? 'red' : 'initial' } }}>
      <DatePicker
        label="Date"
        value={fromDate}
        onChange={handleFromDateChange}
        renderInput={(props) => (
          <TextField
            {...props}
            InputProps={{
              style: {
                '&:hover': {
                  borderColor: fromDateError ? 'red' : 'initial',
                },
                '&.Mui-focused': {
                  borderColor: fromDateError ? 'red' : 'initial',
                },
              },
            }}
          />
        )}
      />
    </FormControl>
  </LocalizationProvider>
  
  
  
  
  
  {/* 
  <FormControl fullWidth sx={{ minWidth: 300 }}>
    <InputLabel id="location-name-label">Location Name</InputLabel>
    <Select
      labelId="location-name-label"
      label="Location Name"
      value={selectedLocation}
      onChange={(e) => handleDetectedType(e, e.target.value)}
    >
      {locationNames.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
        
      ))}
    </Select>
    
  </FormControl> */}
  
  
  
  
       
  
                    {/* <Autocomplete
              fullWidth
              sx={{ minWidth: 200 }}
              
              id="detected-types"
              options={shiftTypesNames}
              getOptionLabel={(option) => option}
  
              // getOptionLabel={(option) => `${option.ShiftName} (${option.FromTime} - ${option.ToTime})`}
              value={selectedShiftTypes}
              onChange={handleShiftType}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option}>
                  {option}
                </Box>
              )}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  
                  label="Shift Type"
                  placeholder="Shift Type"
                />
              )}
            /> */}
   {/* <Autocomplete
    fullWidth
    sx={{ minWidth: 200 }}
    id="detected-types"
    options={shiftTypesNames}
    getOptionLabel={(option) => {
      if (option.ShiftName && option.FromTime && option.ToTime) {
        const fromTime = new Date(`1970-01-01T${option.FromTime}`);
        const toTime = new Date(`1970-01-01T${option.ToTime}`);
      
        const formattedFromTime = fromTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
        const formattedToTime = toTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
      
        return `${option.ShiftName} (${formattedFromTime} - ${formattedToTime})`;
      }
      return '';
    }}
  
  
    
  
    value={selectedShiftTypes || ''}
    onChange={handleShiftType}
    renderOption={(props, option) => (
      <Box component="li" {...props} key={option.ShiftName}>
      {`${option.ShiftName} (${formatTime(option.FromTime)} - ${formatTime(option.ToTime)})`}
    </Box>
    
    )}
    // filterSelectedOptions
    renderInput={(params) => (
      <TextField
      
        {...params}
        label="Shift Type"
        placeholder="Shift Type"
        
      />
      
    )}
  />  */}
  {/* 
  <FormControl fullWidth sx={{ minWidth: 200 }}>
    <InputLabel id="shift-type-label">Shift Type</InputLabel>
    <Select
      labelId="shift-type-label"
      label="Shift Type"
      value={selectedShiftTypes}
      onChange={(e) => handleShiftType(e, e.target.value)}
    >
      {shiftTypesNames.map((option) => (
        <MenuItem key={option.ShiftName} value={option}>
          {`${option.ShiftName} (${formatTime(option.FromTime)} - ${formatTime(option.ToTime)})`}
        </MenuItem>
      ))}
    </Select>
  </FormControl> */}
  
  
  
  {/*the field can only take the input on select disable typing  */}
  
  
  {/* 
  
             <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            
            sx={{ width: '75%' }}
            label="Date"
            value={fromDate}
            onChange={handleFromDateChange}
            
          />
        </LocalizationProvider> */}
  {/* 
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{ width: '75%' }}
            label="To Date"
            value={toDate}
            onChange={handleToDateChange}
          />
        </LocalizationProvider> */}
  {/*            
              <Autocomplete
              fullWidth
              sx={{ minWidth: 100 }}
              
              id="entry-exit"
              options={EmployeeStatus}
              getOptionLabel={(option) => option}
              onChange={handleEmployeeActivityType}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option}>
                  {option}
                </Box>
              )}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params} sx={{ borderColor: isFieldEmpty(selectedLocation) ? 'red' : 'initial' }}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TextField
          sx={{ width: '100%' }}
          label="End Time"
          value={plusTime ? dayjs(plusTime, 'HH:mm:ss').format('HH:mm:ss') : ''}
          readOnly
        />            
            </LocalizationProvider> */}
  
  <LoadingButton  sx={{ width: '25%' }}  type="submit" variant="contained" onClick={handleSubmit} loading={loading} >
            Get Reports
          </LoadingButton>
          {/* disabled={selectedLocation.length === 0 || selectedShiftTypes.length === 0 || fromDate === null || toDate === null} */}
            </Stack>
          </Card>
  
          {/* <Typography variant="caption" color="error">
            {errorMessage}
          </Typography> */}
  

  {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </div>
      )}

      {!loading && (
        <Card sx={{ mt: 4, p: 6 }}>
  
   
        <Button
          sx={{ position: 'absolute', top: 0, right: 15 ,mt : 1}}
          variant="contained"
          color="primary"
          startIcon={<InsertDriveFileIcon />}
          onClick={() => handleButtonClick('excel')}
        >
          Excel <SaveAltIcon />
        </Button>
        
              <TableContainer sx={{ width: '100%' }}>
              <Table>
        
                <React.Fragment>
                <UserListHead 
                  headers={TABLE_HEAD} 
                
                  headLabel={TABLE_HEAD}
                  // rowCount={clients.length}
                  // numSelected={selected.length}
                  />
        
                  {/* No Data has to show only when shift from time and date is in the current time and data */}
                  <TableBody>
        
      
        
              { dateValidate ? (
              <TableRow>
              <TableCell colSpan={9} align="center">
              <Typography variant="h6" color="textSecondary">
              No Data
              </Typography>
              </TableCell>
              </TableRow>
              ) : (
              responseData.sort((a, b) => a.Type.localeCompare(b.Type)).map((item, index) => (
              <TableRow key={index}>
              <TableCell align="left">{index + 1}</TableCell>
              <TableCell align="left">{displayDate}</TableCell>
              <TableCell align="left">{selectedGetLocation}</TableCell>
              <TableCell align="left">{selectedShift}</TableCell>
              <TableCell align="left">{item.Type}</TableCell>
              <TableCell align="left">{item.entry}</TableCell>
      
              <TableCell align="left">{item.exit}</TableCell>
      
                  <TableCell align="left" rowSpan={!rowSpanApplied ? 8 : 1}>
                    {!rowSpanApplied && (
                      <IconButton onClick={() => handlePlay(setEntryvideo, 'Entry Video')}>
                        <PlayArrowIcon />
                      </IconButton>
                    )}
                    {!rowSpanApplied && 'Play'}
                  </TableCell>
      
                  {/* Update the rowSpanApplied flag after the first occurrence */}
                  {rowSpanApplied = true}
      
                  <TableCell align="left" rowSpan={!rowSpanAppliedExit ? 9 : 1}>
                    {!rowSpanAppliedExit && (
                      <IconButton onClick={() => handlePlay(setExitvideo,'Exit Video')}>
                        <PlayArrowIcon />
                      </IconButton>
                    )}
                    {!rowSpanAppliedExit && 'Play'}
                  </TableCell>
      
                  {/* Update the rowSpanApplied flag after the first occurrence */}
                  {rowSpanAppliedExit = true}
      
      
              </TableRow>
              ))
              )}
                  </TableBody>
                  
                  {/* if item.entry_video has a value then append the value for this handlePlay(item.entry_video, 'Entry Video') check the entire records */}
                </React.Fragment>
        
        
              </Table>
              </TableContainer>
        
              <Table>
              {responseData.length > 0 && (
              <TableFooter>
      
      
              <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="right" sx={{  marginLeft: '-100px', color: 'black' }}>
                  <Typography>
                  Total Number Of People Inside: {entryExitDifference}
                  </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>  <Typography   sx={{ color: 'black' }}>
              Total Entry:  {totalEntryCount}  
              </Typography></TableCell>
                  
                  <TableCell>  <Typography  align="left" sx={{ color: 'black' }}>
           Total Exit: {totalExitCount}
      
              </Typography></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
          
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
      
      
      
      {/*   
                <TableCell align="left" sx={{ textAlign: 'left', color: 'black' }}>
        <Typography>
          Total Exit: {totalExitCount}
        </Typography>
      </TableCell> */}
      
        
        
              </TableRow>
              </TableFooter>
              )}
              </Table>
              </Card>
      )}
 

        </Container>

<Dialog open={openVideoPopup} sx={{ maxWidth: '90vw', width: '90vw' }}>
  <DialogTitle sx={{ textAlign: 'center' }}>{dailogTitle}</DialogTitle>
  <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <video controls width="100%" height="auto" style={{ maxWidth: '100%' }}>
      <source src={videoUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </video>

    <LoadingButton
     sx={{ mt: 2, ml: 'auto' }}
      type="submit"
      variant="contained"
      color="error" 
      onClick={handleCloseVideoPopup}
    >
      Cancel
    </LoadingButton>
  </DialogContent>
</Dialog>

        {/* move button toward right side*/}
      </Page>
    )
  }
  
