import React, { useState,useEffect, useRef } from 'react';

import {
  Grid,
  Autocomplete,
  Button,
  TextField,
  Typography,
  FormControl,
  DialogActions,
  Stack,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableContainer,TablePagination,Toolbar,OutlinedInput, Container
} from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { getGroupsByClient } from 'src/api/liveCameras';
import { getDeviceStats } from 'src/api/DashboardDev';
import BarGraph from 'src/sections/reports/BarGraph';
import dayjs from 'dayjs';

export default function DashBoardDepApp() {

    const [groups,setGroups]=useState([]);
    const [selectedGroup,setSelectedGroup] = useState('');
    const [selectedMonth,setSelectedmonth] = useState('');
    const [setYear,setYearSelected] = useState('');
    const [fromDate, setFromDate] = useState(dayjs().startOf('month').hour(0).minute(0))
    const [toDate, setToDate] = useState(dayjs().endOf('day'))
    const [deviceStats,setDeviceStats] = useState([]);
    const [fromDateError,setFromDateErrorMsg]=useState('');
    const [toDateError,setToDateErrorMsg]=useState('');
    const [initialToTime,setInitialToTime]=useState(true)


    const months = [
        { name: 'January', value: '1' },
        { name: 'February', value: '2' },
        { name: 'March', value: '3' },
        { name: 'April', value: '4' },
        { name: 'May', value: '5' },
        { name: 'June', value: '6' },
        { name: 'July', value: '7' },
        { name: 'August', value: '8' },
        { name: 'September', value: '9' },
        { name: 'October', value: '10' },
        { name: 'November', value: '11' },
        { name: 'December', value: '12' }
      ];
    const currentYear = new Date().getFullYear();
    const years = //[currentYear - 1,currentYear,currentYear + 1];
    [
    { name:(currentYear - 1).toString(),value:currentYear - 1 },
    { name:(currentYear).toString(),value:currentYear },
    { name:(currentYear + 1).toString(),value:currentYear + 1 }
    ]



// Use Effect - API Call

useEffect(() => {
    setSelectedmonth(null);
    setYearSelected(null);
    // setFromDate(null); 
    // setToDate(null);
    //debugger
    getGroupsByClient('',(response)=>{
        if(response.status){
          if(response.data !== null){
          setGroups(response.data)
          }
        }
      })  
  }, [])


useEffect(() => {
  const body = { 
    month :0,
    year : 0,
    fromdate : '',
    todate : '',
    group_id : 0
    // client_id : 0
  }
  getDeviceStats(body,(response) => {
    if (response.status === 200) {
      // console.log("messages ",response.data)
      if(response.data !== null){
        setDeviceStats(response.data)
      }
    }
  })
},[])



  const handleMonthchange = (event, value) => {
    setSelectedmonth();
    if (value) {
      // console.log("Selected Month:", value);
      setSelectedmonth(value);
    }else{
        setSelectedmonth();

    }
  };
  
  const handleYearChange = (event, value) => {
    setYearSelected();
    if (value) {
      // console.log("Selected Year:", value.name);
      setYearSelected(value);
    }
    else{
        setYearSelected();
    }
  };
  

      const handleFromDateChange = (date) => {
        setFromDate(date);
      };
    
    //   const handleToDateChange = (date) => {
        

    //     setToDate(date);

    // }

  //   const handleToDateChange = (newDate) => {
  //     // Adjust the time to 23:59:59
  //     const adjustedDate = newDate.endOf('day');

  //     // Update the state with the adjusted date
  //     setToDate(adjustedDate);
  // };

  const handleToDateChange = (newDate) => {
    if(initialToTime){
    const adjustedDate = newDate.endOf('day');
    setToDate(adjustedDate);
    setInitialToTime(false)
    }
    else{
      setToDate(newDate);
    }
  };



      const handleGroup = (event, value) => {
        if (value) {
          // console.log("Selected Group:", value.GroupName,value.Id);
          setSelectedGroup(value.Id)

        } else {
          setSelectedGroup()
          // console.log("No group selected");
        }
      }
      


  
      const handleButtonClick = () => {   
        // (selectedMonth || setYear) && (fromDate || toDate)
        setToDateErrorMsg('')
        setFromDateErrorMsg('')
        if (fromDate === null || toDate === null) {

          if(fromDate === null && toDate === null){
            setFromDateErrorMsg("Please Select From Date");
            setToDateErrorMsg("Please Select to Date");
            return;
          }
          else if(fromDate === null){
            setFromDateErrorMsg("Please Select From Date");
            return;
          }
          else if(toDate === null){
            setToDateErrorMsg("Please Select to Date");
            return;
          }
        } 

        // else if(selectedMonth && setYear){
        //   const yearValue = setYear.name !== null ? setYear.name : '';
        //   const monthValue = selectedMonth.value !== null ? selectedMonth.value : '';
        //   const groupValue = selectedGroup ? selectedGroup : 0;

        //   const body = { 
        //     month :monthValue,
        //     year : yearValue,
        //     fromdate:'',
        //     todate:'',
        //     group_id : groupValue
        //     // client_id : 0
        //   }
        //   getDeviceStats(body,(response) => {
        //     if (response.status === 200) {
        //       console.log("messages ",response.data)
        //       setDeviceStats(response.data)
        //     }
        //   })
  
        // }
        else {
          setToDateErrorMsg('')
          setFromDateErrorMsg('')
          const fromDatefor = fromDate;
          const toDatefor = toDate;
  
  
        const isValidDate = (value) => {
          const date = new Date(value);
          return !isNaN(date.getTime());
        };
  
        const parseDate = (dateString) => {
          if (!isValidDate(dateString)) {
            console.error("Invalid date:", dateString);
            return null; // Return null if the date string is invalid
          }
          return new Date(dateString);
        };
  
        const formatDateTime = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          const seconds = String(date.getSeconds()).padStart(2, '0');
  
          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };
  
        const fromDateObj = parseDate(fromDatefor);
        const toDateObj = parseDate(toDatefor);
        const formattedFromDate = ''
        const formattedToDate = ''

        if (fromDateObj && toDateObj) {
          const formattedFromDate = formatDateTime(fromDateObj);
          const formattedToDate = formatDateTime(toDateObj);
          // console.log('Formatted fromDate:', formattedFromDate ,formattedToDate );
          
          const groupValue = selectedGroup ? selectedGroup : 0;
  
          const body = { 
            month :0,
            year : 0,
            fromdate : formattedFromDate,
            todate : formattedToDate,
            group_id : groupValue
            // client_id : 0
          }
          getDeviceStats(body,(response) => {
            if (response.status === 200) {
              // console.log("messages ",response.data)
              setDeviceStats(response.data)
            }
          })
  
        }

        }

      };


      const handleClearButtonClick = () => {
        setSelectedmonth(null);
        setYearSelected(null);
        setFromDate(null); 
        setToDate(null);
        setSelectedGroup('');
        setDeviceStats([]);
        setToDateErrorMsg('')
        setFromDateErrorMsg('')
     }

      
  return (

    <>
    
    <div>
            <Typography variant="h4" gutterBottom>
              Dashboad
            </Typography>
      <Grid sx={{ marginLeft: '1rem' }}>
        <Grid container spacing={2} alignItems="center" sx={{marginTop:'0.2rem'}}>


            <Grid item xs={3}>

            <Autocomplete
          
          id="tags-outlined-client"
          options={groups}
          getOptionLabel={(option)=>option.GroupName}
          onChange={handleGroup}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Group"
              placeholder="Search Group Name..."
            //   error={error && isClientSelected}

            />

          )}
        />
        {/* {error && isClientSelected && <Typography variant="body2" color="error">Select Client</Typography>}  */}
            </Grid>
            {/* <Grid item xs={3}>

            <Autocomplete
                value={selectedMonth}
                options={months}
                getOptionLabel={(option) => option.name}
                onChange={handleMonthchange}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    label="Month"
                    placeholder="Select Month"
                    variant="outlined"
                    />
                )}
                />
            </Grid>
          <Grid item xs={3}>

                          <Autocomplete
                          value={setYear}
                          onChange={handleYearChange}
                          options={years}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                              <TextField
                              {...params}
                              label='Year'
                              placeholder="Select Year"
                              variant="outlined"
                              />
                          )}
                          />
          </Grid> */}




               <Grid item xs={3}>
    
                <FormControl fullWidth>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="From Date"
                  id="from-date"
                  ampm={false}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  format="YYYY-MM-DD HH:mm"
                  value={fromDate}
                  onChange={handleFromDateChange}
                  sx={{ border: 'none' }}
                />
              </LocalizationProvider> */}
               <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label="From Date"
        id="from-date"
        format="YYYY-MM-DD HH:mm"
        value={fromDate}
        onChange={handleFromDateChange}
        ampm={false} // This disables the AM/PM format
        sx={{ border: 'none' }}
        maxDate={dayjs()}
      />
    </LocalizationProvider>
              {fromDateError && <Typography color="error" variant="body2" align="center">{fromDateError}</Typography>}

            </FormControl>  
            </Grid>
            <Grid item xs={3}>
    
                    <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                    label="To Date"
                    id="to-date"
                    ampm={false}
                    // viewRenderers={{
                    //     hours: renderTimeViewClock,
                    //     minutes: renderTimeViewClock,
                    //     seconds: renderTimeViewClock,
                    // }}
                    format="YYYY-MM-DD HH:mm"
                    value={toDate}
                    onChange={handleToDateChange}
                    sx={{ border: 'none' }}
                    minDate={fromDate}
                    maxDate={dayjs()}
                    />
                </LocalizationProvider>
                {toDateError && <Typography color="error" variant="body2" align="center">{toDateError}</Typography>}

                </FormControl>  
                </Grid>

        

            <DialogActions>
          <Button onClick={handleButtonClick} variant='contained' color="primary">Get Reports</Button>
          <Button color='error'  variant='contained' onClick={handleClearButtonClick}>Cancel</Button>
        </DialogActions>
        </Grid>
       </Grid> 


    <div style={{ marginTop: '30px' }}></div>

     <Container>
        <BarGraph data={deviceStats}></BarGraph>
    </Container> 
    <div style={{ marginTop: '30px' }}></div>
{/* Table content */}
    <div >
    <TableContainer sx={{ width: '100%' }}>
  <Table>
    <TableHead>
      <TableRow sx={{ backgroundColor: 'rgba(102, 178, 255 , 1)' }}>
        <TableCell align="left">S.No</TableCell>
        <TableCell align="left">Camera Name</TableCell>
        <TableCell align="left">True Events</TableCell>
        <TableCell align="left">False Events</TableCell>
        <TableCell align="left">Escalated Events</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>

    <TableRow >
        <TableCell align="center" colSpan={2} style={{ fontWeight: 'bold' }}>Total</TableCell>
        <TableCell align="left"  style={{ fontWeight: 'bold' }} >
         {deviceStats.reduce((total, row) => total + row.true_event_count, 0)}
        </TableCell>
        <TableCell align="left"  style={{ fontWeight: 'bold' }}>
          {deviceStats.reduce((total, row) => total + row.false_event_count, 0)}
        </TableCell>
        <TableCell align="left"  style={{ fontWeight: 'bold' }}>
         {deviceStats.reduce((total, row) => total + row.escalated_count, 0)}
        </TableCell>
    </TableRow>

      {deviceStats.map((row, index) => (
        <TableRow key={row.id}>
          <TableCell align="left">{index + 1}</TableCell>
          <TableCell align="left">{row.devicename}</TableCell>
          <TableCell align="left">{row.true_event_count}</TableCell>
          <TableCell align="left">{row.false_event_count}</TableCell>
          <TableCell align="left">{row.escalated_count}</TableCell>
        </TableRow>
      ))}
    
    
    </TableBody>
  </Table>
</TableContainer>


    </div>
       
    </div>

    </>
  )
}