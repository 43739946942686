import React, { useState, useEffect } from "react";
import {
  Checkbox,
  Button,
  Card,
  Container,
  FormControl,
  Autocomplete,
  TextField,
  Grid,
  Box,
  CardContent,
 
  MenuItem,
  Select, InputLabel,
  Stack,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Page from "../components/Page";
import {
  getCamerasList_Live,
  getGroupsByClient,
  getCamerasByGroupID,
  getCamerasList,
} from "src/api/liveCameras";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getClientList } from "src/api/analytics";
import { VpnLockSharp } from "@mui/icons-material";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function LiveCameras() {
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedCameras, setSelectedCameras] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [liveCamerasList, setLiveCamerasList] = useState([]);
  const [clientGroupList, setClientGroupsList] = useState([]);
  const [camerasByGroupId, setCamerasByGroup] = useState([]);
  const [camerawithoutGroups, setCameraListWithoutGroups] = useState([]);
  const [showOptions, setShowOptions] = useState(true);
  // Inside the component function
  const [gridLayout, setGridLayout] = useState({ xs: 12 });
  const [errorMessage, setErrorMessage] = useState('');

  const [checkValue,setCheckValue]=useState(false)

  const [openAuto,setOpenAuto]=useState(false)

  const [activeCameras, setActiveCameras] = useState([])
  const [inputValue, setInputValue] = useState([]);
  //Live cameras Length and height adjust
  const [outerBoxWidth, setOuterBoxWidth] = useState('')
  const [outerBoxHeight, setOuterBoxHeight] = useState('')
  const [cameWidth , setCamewidth] = useState('');
  const [cameHeight , setCameHeight] = useState('');
  const [xsCount , setXSCountVal] = useState(null);

 // const [showClient,setShowClient] = useState(false)
  const [clientList,setClientList] = useState([]);
  const [clientName,selectedClientName] = useState([]);
  const [saveError, setSaveError] = useState(false); 
  const [isClientSelected, setIsClientSelected] = useState(false);
  const [isClient,setIsClient] = useState(false)
  const [totalCameras,setTotalCameras] = useState([])

  const [selectedClient,setSelectedClient]=useState('')

  const [errorGroup, setErrorGroup] = useState(null);
  const [client, setClient] = useState('');

  const handleClient = (_, value) => {
   // debugger
    setClientGroupsList([])
    setCameraListWithoutGroups([])
    setCamerasByGroup([])
    let isGrouped = false;
    if(value){
      //setSelectedClient(value)
      // if(!selectedClient && isClient){
      //   console.log('select client')
      // }
      if(checkValue){
        setCheckValue(!checkValue)
      }
      let clientId = value.ID;
      setClient(clientId)
      let body = {"client_id":clientId}
      getGroupsByClient(body,(response) => {
        if (response.status === 200) {
          if(response.data.length>0){
            setClientGroupsList(response.data);
            isGrouped = true;
          }
          else{
            isGrouped = false;
          }
        }
      });

      if(!isGrouped){
        let ClientCameras = totalCameras.filter(item=>item.clientid===clientId);
        if(ClientCameras.length>0){
          setCameraListWithoutGroups(ClientCameras)
        }
      }
      else{

      }
    }
    else{
      setClient('')
    }
   };

  // Inside the handleSubmit function

  useEffect(() => {
    // getGroupsByClient((response) => {
    //   if (response.status === 200) {
    //     // console.log("response Data group ", response.data);
    //     setClientGroupsList(response.data);
    //   }
    // });
  }, []);

  useEffect(() => {
   // debugger
    let userType = localStorage.getItem('user_type')
   
    getCamerasList((response) => {
      if (response.status === 200) {
        // console.log("response data cameras", response.data);
        if(response.data !== null){
          if(userType==='2'){
            setCameraListWithoutGroups(response.data);
          }
          else{
            setTotalCameras(response.data)
          }
        }
      }
    });

    if(userType === "2"){
      setIsClient(true)
      getGroupsByClient('',(response) => {
        if (response.status === 200) {
          // console.log("response Data group ", response.data);
          setClientGroupsList(response.data);
        }
      });
    }
    else{
      setIsClient(false)

      getClientList((response) => {   
        if (response.status === 200) {
          setClientList(response.data)
        }
      })
    }

  }, []);

  useEffect(() => {
   //debugger
   if(clientGroupList.length>0){
      getCamerasByGroupID(selectedGroup, (response) => {
        if (response.status === 200) {
          // console.log("cameras List by Group ", selectedGroup, response.data);
          setCamerasByGroup(response.data);
        }
      });
    }
  }, [selectedGroup]);

  useEffect(() => {
    //debugger
   // console.log("liveCamerasList.......................", liveCamerasList.length);
  
    // switch (liveCamerasList.length) {
    //   case 1:
    //     setOuterBoxWidth('1480px');
    //     setOuterBoxHeight('550px');
    //     setCamewidth('1470px');
    //     setCameHeight('500px');
    //     break;
    //   case 2:
    //     setOuterBoxWidth('740px');
    //     setOuterBoxHeight('550px');
    //     setCamewidth('730px');
    //     setCameHeight('495px');
    //     setXSCountVal(6);
    //     break;
    //   case 3 : 
    //     setOuterBoxWidth('730px');
    //     setOuterBoxHeight('270px');
    //     setCamewidth('700px');
    //     setCameHeight('235px');
    //     setXSCountVal(6);
    //     break;
    //   case 4:
    //     setOuterBoxWidth('730px');
    //     setOuterBoxHeight('270px');
    //     setCamewidth('700px');
    //     setCameHeight('235px');
    //     setXSCountVal(6);
    //     break;
    //   case 5 :
    //     setOuterBoxWidth('480px');
    //     setOuterBoxHeight('200px');
    //     setCamewidth('475px');
    //     setCameHeight('165px');
    //     setXSCountVal(4);
    //     break;
    //   case 6 :
    //     setOuterBoxWidth('480px');
    //     setOuterBoxHeight('200px');
    //     setCamewidth('475px');
    //     setCameHeight('165px');
    //     setXSCountVal(4);
    //     break;
    //   case 7 :
    //     setOuterBoxWidth('360px');
    //     setOuterBoxHeight('200px');
    //     setCamewidth('355px');
    //     setCameHeight('160px');
    //     setXSCountVal(3);
    //     break;  
    //   case 8 :        
    //   // setOuterBoxWidth('100%');
    //   // setOuterBoxHeight('100%');
    //   // setCamewidth('100%');
    //   // setCameHeight('90%');
    //     setOuterBoxWidth('360px');
    //     setOuterBoxHeight('200px');
    //     setCamewidth('355px');
    //     setCameHeight('160px');
    //     setXSCountVal(3);
    //     break;  
    //   default:
    //     setOuterBoxWidth('360px');
    //     setOuterBoxHeight('200px');
    //     setCamewidth('355px');
    //     setCameHeight('160px');
    //     setXSCountVal(3);
    //     break;
    // }

    switch (liveCamerasList.length) {
         
        case 1:
          //debugger
          setOuterBoxWidth('100%');
          setOuterBoxHeight('80vh');
          setCamewidth('100%');
          setCameHeight('75vh');
          setXSCountVal(12);
          break;
        case 2:
          setOuterBoxWidth('100%');
          setOuterBoxHeight('60vh');
          setCamewidth('100%');
          setCameHeight('50vh');
          setXSCountVal(6);
          break;
        case 3:
          setOuterBoxWidth('100%');
          setOuterBoxHeight('40vh');
          setCamewidth('100%');
          setCameHeight('35vh');
          setXSCountVal(6);
          break;
        case 4:
          setOuterBoxWidth('100%');
          setOuterBoxHeight('40vh');
          setCamewidth('100%');
          setCameHeight('35vh');
          setXSCountVal(6);
          break;
        case 5:
          setOuterBoxWidth('100%');
          setOuterBoxHeight('40vh');
          setCamewidth('100%');
          setCameHeight('35vh');
          setXSCountVal(4);
          break;        
        case 6:
            setOuterBoxWidth('100%');
            setOuterBoxHeight('40vh');
            setCamewidth('100%');
            setCameHeight('35vh');
            setXSCountVal(4);
          break;   
        default:
          setOuterBoxWidth('360px');
          setOuterBoxHeight('200px');
          setCamewidth('355px');
          setCameHeight('160px');
          setXSCountVal(3);
          break;
  } 
  }, [liveCamerasList]);
  

  const handleGroupChange = (event) => {
    //debugger
    if(event.target.value && errorGroup){
      setErrorGroup(null)
    }
    setLiveCamerasList([]);
    setSelectedCameras([]);
    setCamerasByGroup([]);
    // console.log("selected Group", event.target.value);
    setSelectedGroup(event.target.value);
    setInputValue([])
    setActiveCameras([])
    setOpenAuto(false)
  };

  const handleCameraChange = (event, newValue) => {
    setSelectedCameras(newValue);
  };
  const handleGridLayout = () => {
    //debugger
    if (activeCameras.length === 1 || activeCameras.length === 2 || activeCameras.length === 3) {
      setGridLayout({ xs: 3 });
    }  
    else {
      const numRows = Math.ceil(activeCameras.length / 4);
      const numCols = Math.min(4, activeCameras.length);
      setGridLayout({
        xs: 12 / numCols,
        style: { gridColumnEnd: `span ${numRows}` },
      });
    }
  };

  const handleSubmit = () => {
    //debugger
    setSaveError(false)
    setErrorGroup(null)
    if(!isClient && selectedClient===''){
     // console.log('select client')
      setSaveError(true)
      return
    }

    if(clientGroupList.length>0 && (selectedGroup==='' || selectedGroup===null)){
      setErrorGroup({ message: 'Select Group' })
      return
    }
  
    setLiveCamerasList([]);
    // const deviceIds = selectedCameras.map((camera) => {
    //   if (clientGroupList.length !== 0) {
    //     return camera.DeviceId; // Change DeviceId to deviceid
    //   } else {
    //     return camera.deviceid;
    //   }
    // });

    if (activeCameras.length === 0) {
      setErrorMessage('Please Select Atleast one Camera');
      return;
    } else {
      setErrorMessage('');

      getCamerasList_Live(activeCameras, (response) => {
        if (response.status === 200) {
          // console.log("resonce Live ", response.data);
          setLiveCamerasList(response.data);
          setSubmitted(true); // Set submitted to true after fetching live cameras list
          // handleGridLayout(); // Update grid layout after data fetching
          setOpenAuto(false)
        }
      });
      

    }
  };

  const handleClear = () => {
    setLiveCamerasList([]);
    setSelectedCameras([]);
    setSelectedGroup("");
    setCamerasByGroup([]);
    setSubmitted(false); // Reset the submitted state when clearing
    setActiveCameras([])
    setOpenAuto(false)
    setInputValue([])
    setCheckValue(false)
    setClient('')
  };

  const getGridLayout = () => {
    if (!submitted || selectedCameras.length === 0) {
      // If not submitted or no cameras selected, return default layout
      return {
        xs: 12, // Occupy the entire width of the screen
      };
    } else {
      // Logic to calculate grid layout based on selected cameras
      if (selectedCameras.length === 1) {
        return {
          xs: 12, // Occupy the entire width of the screen
        };
      } else if (selectedCameras.length === 2 || selectedCameras.length === 3) {
        return {
          xs: 6, // Display two or three cameras side by side in one row
        };
      } else {
        // Render more than three cameras with each row containing two cameras
        const numRows = Math.ceil(selectedCameras.length / 4); // Calculate the number of rows needed
        const numCols = Math.min(4, selectedCameras.length); // Maximum of 4 columns
        const rowSpan = selectedCameras.length === 1 ? numRows : 1; // Row span for grid item

        return {
          xs: 12 / numCols, // Each camera takes a portion of the row width
          style: { gridColumnEnd: `span ${rowSpan}` }, // Set the grid item to span multiple rows
        };
      }
    }
  };

  const handleLiveCamera = (event,newValue)=>{
       // debugger
        setSelectedCameras(newValue);
        if (!newValue || newValue.length === 0) {
            setLiveCamerasList([]); 
          }
        
          let lengthofCameras=clientGroupList.length?camerasByGroupId.length:camerawithoutGroups.length
          let cameraValues=clientGroupList.length?camerasByGroupId:camerawithoutGroups
          
      
                    if (newValue.some(obj => obj.hasOwnProperty('all'))) {
                      if((newValue[0].all && newValue.length!==2) || (newValue[newValue.length-1].all)){
                        setOpenAuto(false);
                      }
                      else{
                        setOpenAuto(true);
                      }
                    } else {
                     // let len=clientGroupList.length?camerasByGroupId.length:camerawithoutGroups.length
                      if(newValue.length===lengthofCameras){
                        setOpenAuto(false);
                      }
                      else{
                        setOpenAuto(true);
                      }
                    }
                  
                    if (newValue.length > 1 && newValue.every((entry, index, arr) => JSON.stringify(entry) === JSON.stringify(arr[0]))) {
                      newValue = [];
                    }
                    
                    const containsAllProperty = newValue.some(obj => obj.hasOwnProperty('all'));
      
                    if(containsAllProperty){
                      if(newValue.length==1){
                        //camerasByGroupId
                        //let val=clientGroupList.length?camerasByGroupId:camerawithoutGroups
                        setSelectedCameras(cameraValues);              
                      }
                      else{
                        let val = newValue.filter(item => !item.all)
                        setSelectedCameras(val)
                      }               
                    }
                  
                  
                    if (newValue.length === 0 || !containsAllProperty) {
                      setCheckValue(false);
                    }
                  
                    let actCameras = [];
                  
                    if (newValue.length > 0 && !containsAllProperty) {
                      newValue.forEach((row) => {
                        clientGroupList.length?actCameras.push(row.DeviceId):actCameras.push(row.deviceid)
                      });
      
                      //let lenVal=clientGroupList.length?camerasByGroupId.length:camerawithoutGroups.length
                      if (lengthofCameras === actCameras.length) {
                        setCheckValue(true);
                        setOpenAuto(false);
                      }
                  
                      setActiveCameras([...actCameras]);
                      setInputValue([...newValue]);
                    }
                  
                    if (newValue.length > 0 && containsAllProperty) {
                      
                      let Cameras =cameraValues;
                  
                      actCameras = [];
                      
                      //let samplVal=clientGroupList.length?camerasByGroupId.length:camerawithoutGroups.length
                      if (activeCameras && activeCameras.length === lengthofCameras) {
                        setCheckValue(false);
                        let deviceIds =[] 
                        
                        if(clientGroupList.length){
                        deviceIds=newValue.filter(obj => !obj.hasOwnProperty('all')).map(obj => obj.DeviceId);
                        Cameras = Cameras.filter(item => !deviceIds.includes(item.DeviceId));
                        Cameras.forEach((row) => {
                          actCameras.push(row.DeviceId);
                        });
                        setInputValue(camerasByGroupId);
                        }
                        else{
                          deviceIds=newValue.filter(obj => !obj.hasOwnProperty('all')).map(obj => obj.deviceid);
                          Cameras = Cameras.filter(item => !deviceIds.includes(item.deviceid));
                          Cameras.forEach((row) => {
                            actCameras.push(row.deviceid);
                          });
                          setInputValue(camerawithoutGroups);
                        }
      
                       
                        setActiveCameras([...actCameras]);
                       
                        //setOpenAuto(false);
                        return;
                      }
                     
                      if (!checkValue) {
                       
                        if(clientGroupList.length){
                          Cameras.forEach((row) => {
                            actCameras.push(row.DeviceId);
                          });
                          setActiveCameras([...actCameras]);
                          setInputValue(camerasByGroupId);
                          setCheckValue(true);
                        }
                       else{
                        Cameras.forEach((row) => {
                          actCameras.push(row.deviceid);
                        });
                        setActiveCameras([...actCameras]);
                        setInputValue(camerawithoutGroups);
                        setCheckValue(true);
                       }
                      } else {
                        setActiveCameras([]);
                        setInputValue([]);
                      }
                    } else if (newValue.length === 0) {
                      setActiveCameras([]);
                      setCheckValue(false);
                      setInputValue([]);
                      setOpenAuto(true)
                    }         
  }

  const [showGrid,setShowGrid]=useState(false)
  const showFilter = ()=>{
    setShowGrid(!showGrid)
  }

  const handleFilterOptions = (options, params) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    
    // Separate selected and unselected options
    const selectedOptions = filtered.filter(option => activeCameras.includes(option.deviceid));
    const unselectedOptions = filtered.filter(option => !activeCameras.includes(option.deviceid));
    
    // Sort options: selected options first, then unselected
    const sortedOptions = [...selectedOptions, ...unselectedOptions];
  
    return [{ title: 'Select All', all: true }, ...sortedOptions];
  };

  return (
    <Page title="Live">
      <Container maxWidth="xl">
        
        {!showGrid && <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Live
          </Typography>
        </Stack>}

        {!showGrid && <Grid
          container
          spacing={1}
          style={{ display: showOptions ? "flex" : "none" }}
        >
         
        <Grid container spacing={2} >

            {!isClient && (<Grid item xs={3}>                           
                <FormControl fullWidth>                                             

                    {/* <Autocomplete
                          value={selectedClient}
                          onChange={(event,val)=>{
                            setSelectedClient(val)
                            handleClient(event,val)
                          }}
                          options={clientList}
                          getOptionLabel={(option) => option.displayname || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select Client"
                              variant="outlined"
                              error={saveError && !isClientSelected}
                            />
                          )}
                          filterOptions={(options, state) => {
                            return options.filter((option) => 
                              option.displayname.toLowerCase().includes(state.inputValue.toLowerCase())
                            );
                          }}
                    /> */}

                    <Autocomplete
                                fullWidth
                                id="tags-outlined"
                                options={clientList.map((client, clientIndex) => ({
                                  ...client,
                                  customKey: clientIndex, 
                                }))}
                                isOptionEqualToValue={(option, value) =>
                                  option.customKey === value.customKey
                                }
                                getOptionLabel={(option) => option.displayname}
                               // onChange={handleClient}
                               onChange={(event,val)=>{
                                setSelectedClient(val)
                                handleClient(event,val)
                              }}
                                value={client.ID}
                                filterSelectedOptions
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Clients"
                                    placeholder="Enter Client Name"
                                  />
                                )}
                                renderOption={(props, option) => (
                                  <li {...props} key={option.customKey}>
                                    {option.displayname}
                                  </li>
                                )}
                              />

                  {(saveError) && (<Typography variant="body2" color="error">Please select a client.</Typography>)}                                                     
                </FormControl>                                          
            </Grid>)}

            {(clientGroupList.length > 0 ) && (
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="group-label">Group</InputLabel>
                <Select
                  labelId="group-label"
                  value={selectedGroup}
                  onChange={handleGroupChange}
                  label="Group"
                >
                  {clientGroupList.map((group) => (
                    <MenuItem key={group.Id} value={group.Id}>
                      {group.GroupName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errorGroup && <Typography color="error" variant="body2" align="center">{errorGroup.message}</Typography>}
            </Grid>
            )}

            <Grid item xs={(clientGroupList.length > 0 || !isClient) ? 3 : 10}>
              
              <Autocomplete
                multiple
                id="tags-outlined-cameras"
                options={
                  clientGroupList.length > 0
                    ? camerasByGroupId
                    : camerawithoutGroups
                }
                getOptionLabel={(option) => {
                  const options =
                    clientGroupList.length > 0
                      ? camerasByGroupId
                      : camerawithoutGroups;
                      return options === camerawithoutGroups
                      ? option.all ? 'Select All' : `${option.deviceid} ${option.devicename}`
                      : option.all ? 'Select All' : `${option.DeviceId} ${option.devicename}`;
                }}
                
                onChange={handleLiveCamera}

                disableCloseOnSelect={openAuto}
                open={openAuto}
                onOpen={() => setOpenAuto(true)}
                onClose={() => setOpenAuto(false)}

                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <>
                      {(!clientGroupList.length || selectedGroup) && option.all ? (
                      <>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={checkValue}
                          onChange={() => setCheckValue(!checkValue)}
                        />
                        {option.title}
                      </>
                      ) :
                      option.deviceid ? ( // no group cameras
                        <>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={activeCameras.includes(option.deviceid)}
                            onChange={() => {
                              debugger
                              const isChecked = activeCameras.includes(option.deviceid);
                              const newActiveCameras = isChecked
                                ? activeCameras.filter((deviceId) => deviceId !== option.deviceid)
                                : [...activeCameras, option.deviceid];
                              setActiveCameras(newActiveCameras);
                            }}
                          />
                          {`${option.deviceid} ${option.devicename}`}
                        </>
                      ) : selectedGroup && ( // group cameras
                        <>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={activeCameras.includes(option.DeviceId)}
                          onChange={() => {
                            debugger
                            const isChecked = activeCameras.includes(option.DeviceId);
                            const newActiveCameras = isChecked
                              ? activeCameras.filter((deviceId) => deviceId !== option.DeviceId)
                              : [...activeCameras, option.DeviceId];
                            setActiveCameras(newActiveCameras);
                          }}
                        />
                        {`${option.DeviceId} ${option.devicename}`}
                      </>
                      )                  
                    }
                    </>
                  </li>
                )}
                
                onInputChange={(event, newInputValue) => {
                  if (!newInputValue) {
                    setLiveCamerasList([]); // Clear live cameras list when input value is empty
                  }
                }}
                
                //value={selectedCameras}
                value={ (checkValue) ? [{ title: 'Select All', all: true }] : inputValue.filter((camera) =>clientGroupList.length?activeCameras.includes(camera.DeviceId):activeCameras.includes(camera.deviceid) )}
                

                filterOptions={handleFilterOptions}

                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Camera"
                    placeholder="Search Camera ID Or Name..."

                  />
                )}
                renderTags={() => null} 

                clearOnBlur={true} // Clear the value when Autocomplete loses focus
              />

              {errorMessage && <Typography color="error" variant="body2" align="center">{errorMessage}</Typography>}

            </Grid>

            <Grid item xs={2}>
              <Stack direction="row" spacing={1} alignItems="center">            
                
                <LoadingButton variant="contained" onClick={handleSubmit}>
                  Submit
                </LoadingButton>

                <LoadingButton
                  variant="contained"
                  color="error"
                  onClick={handleClear}
                >
                  Cancel
                </LoadingButton>
                
              </Stack>
            </Grid>
        </Grid>   
        </Grid>}

        {activeCameras.length>0 && submitted && (<Grid item xs={12} mt={2}>
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton variant="contained" onClick={showFilter}>
              Show/Hide Filters
            </LoadingButton>
          </Stack>
        </Grid>)}

        {submitted && liveCamerasList.length > 0 && (
          <Grid container spacing={2} mt={3} >
            {/* Render grid items dynamically */}
            {liveCamerasList.map((camera, index) => (
              <Grid item {...gridLayout} key={index} sx={{ padding: '10px' }}  xs={xsCount} > 
                <Card sx={{ background: "#f0f3f5", padding: "10px",width:outerBoxWidth, height:outerBoxHeight  }}>
                <CardContent sx={{ pt: 1, pb: 0 }}>
                    <Typography variant="h6">
                      {camera.devicename}
                    </Typography>
                  </CardContent>
                   <Box sx={{   
                        borderRadius: 3.5,    
                        width: cameWidth ,
                        height: cameHeight,
                        // width:"100%",
                        // height:"100%",
                      }}>                 
                          <iframe id="fp_embed_player"    
                          src={`https://restreamservers.com:8888/embed_player?urlServer=wss://restreamservers.com:8443&streamName=${camera.substream}&amp?&autoplay=1&mediaProviders=WebRTC,Flash,MSE,WSPlayer&amp;`}                       
                          allowFullScreen={true} 
                          style={{
                             width: cameWidth ,
                              height: cameHeight,
                               border: 'none',padding:0,margin:0 }}                           
                          >
                          </iframe>             
                  </Box>




                </Card>
              </Grid>
            ))}
          </Grid>
        )}


      </Container>
    </Page>
  );
}